/* Basic editor styles */
.ProseMirror > * + * {
  margin-top: 0.75em;
}
.ProseMirror h1 {
  position: relative;
}
.ProseMirror h1:after {
  content: 'Title';
  position: absolute;
  bottom: 100%;
  left: 0;
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: -0.01em;

}
.ProseMirror h1.is-empty:first-child::before,
.heading-is-empty .ProseMirror h1:first-child::before {
  content: 'Insert title here...';
  line-height: 1.1;
  /* font-family: "Inter UI"; */
  font-weight: 900;
  float: left;
  color: #adb5bd;
  pointer-events: none;
  height: 0;
}

.ProseMirror ul,
.ProseMirror ol {
  padding: 0 1rem;
}

.ProseMirror h1,
.ProseMirror h2 ,
.ProseMirror h3,
.ProseMirror h4 ,
.ProseMirror h5 ,
.ProseMirror h6 {
  line-height: 1.1;
  /* font-family: "Inter UI"; */
  /* font-weight: 900; */
  color: #171717;
}

.ProseMirror h1 > *,
.ProseMirror h2 > *,
.ProseMirror h3 > *,
.ProseMirror h4 > *,
.ProseMirror h5 > *,
.ProseMirror h6 > * {
  line-height: 1.1;
  font-family: "Inter";
  /* font-weight: 900; */
  color: #171717;
}

.ProseMirror h1{

/* Display/L/Extrabold */
    font-family: Inter;
    font-size: 32px;
    font-style: normal;
    font-weight: 800;
    line-height: 36px; /* 112.5% */
    letter-spacing: -0.64px;
}

.ProseMirror h2{
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px; /* 125% */
  letter-spacing: -0.48px;
}

.ProseMirror h3{
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 120% */
}

.ProseMirror h4{
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 146.667% */
  letter-spacing: -0.15px;
}

.ProseMirror p{
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 153.846% */
  letter-spacing: -0.13px;

}

.ProseMirror p a {
  color: var(--night-symbols-primary);
  padding-bottom: 2px;
  border-bottom: 1px solid;
  cursor: pointer;
}

.ProseMirror p a:hover {
  color: var(--night-symbols-primary-hv);
}

/* .ProseMirror p {
  font-weight: 400;
  font-style: normal;
  font-size: 1em;
  color: var(--color-light-text);
} */

.ProseMirror code {
  background-color: rgba(97, 97, 97, 0.1);
  color: #616161;
}

.ProseMirror pre {
  background: #0d0d0d;
  color: #fff;
  /* font-family: "JetBrainsMono", monospace; */
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
}

.ProseMirror pre code {
  color: inherit;
  padding: 0;
  background: none;
  font-size: 0.8rem;
}

.ProseMirror img {
  max-width: 100%;
  height: auto;
}

.ProseMirror blockquote {
  padding-left: 1rem;
  border-left: 2px solid rgba(13, 13, 13, 0.1);
}

.ProseMirror hr {
  border: none;
  border-top: 2px solid rgba(13, 13, 13, 0.1);
  margin: 2rem 0;
}

.editor-wrapper {
  height: calc(100vh - 40px);
  background: #f3f3f3
}

.editor-wrapper:not(.outline-mode) .editor-content .refresh-title {
  display: none !important;
}

.editor-wrapper.outline-mode{
  background: #f4f2fc
}

.editor-wrapper .editor-content .refresh-title:not(:last-child) {
  display: none !important;
}

.editor-wrapper .editor-content .refresh-title path {
  fill: var(--night-symbols-primary);
}

@media (max-width: 1285px) {
  .editor-wrapper .editor-content .refresh-title {
    left: 70px;
  }
}

.editor-content__inner-wrapper {
  border-radius: 8px;
  background: #fff;
  max-width: 800px;
  margin: 16px auto 120px;
  width: 100%;
  height: auto;
  padding: 24px 36px 60px;
  margin-bottom: 40px;
}
.editor-content__inner-wrapper .title-label {
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: -0.01em;
}

.title-label__wrapper {
  display: flex;
  align-items: center;
}


.title-label__wrapper .refresh-title {
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: -0.01em;
  color: var(--night-symbols-primary);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  cursor: pointer;
  z-index: 1;
  margin-left: 6px;
}
.title-label__wrapper .refresh-title.regenerate-disabled{
  color: var(--default-symbols-secondary);
  cursor: not-allowed
}
.title-label__wrapper .refresh-title.regenerate-disabled path{
  fill: var(--default-symbols-secondary);
}

.editor-wrapper .editor-content {
  /*height: 100%;*/
  /*overflow: auto;*/
  position: relative;
  display: flex;
  flex-direction: column;
}

.editor-wrapper .ProseMirror {
  /*height: calc(100vh - 98px);*/
  /*overflow: scroll;*/
  /*padding: 0 36px 60px;*/
  padding: 0;
}

.ProseMirror:focus {
  outline: none;
}

.editor-content-container{
  padding: 0 16px
}
