.new-article-modal__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 21;
    padding: 68px 0;
    overflow: auto;
}