.article-keywords-table-group-loading .loading-block {
    border-radius: 12px;
}

.article-keywords-table-group-loading--row {
    display: flex;
    align-items: center;
}

.article-keywords-table-group-loading--row .flex-grow {
    flex-grow: 1;
}