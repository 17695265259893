.competition-item {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px;
  margin-bottom: 8px;
  background: var(--default-surfaces-light);
  border-radius: 12px;

}

.competition-body {
  width: 100%;
  display: flex;
  align-items: center;
}

.circle-position {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.green {
  background-color: #6ed692;
}

.yellow {
  background-color: #f7ca52;
}

.orange {
  background-color: #ef995d;
}

.red {
  background-color: #e898a2;
}

.item-position {
  padding: 2px;
  height: 16px;
  width: 16px;
}

.number-position {
  border-radius: 50px;
  background: var(--default-symbols-primary);
  font-size: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  color: rgb(243, 243, 243)
}

.item-content {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding-right: 30px;
}

.item-top {
  display: flex;
  justify-content: flex-start;
  font-weight: 500;
  font-size: 12px;
  padding-bottom: 8px;
}

.item-top a {
  display: flex;
  justify-content: flex-start;
  font-weight: 500;
  font-size: 12px;
}

.item-top-stats {
  font-size: 13px;
  font-weight: 800;
  line-height: 16px;
  letter-spacing: -0.01em;
  color: var(--default-symbols-primary);
  margin-left: 4px;
}
.item-top-stats.count {
  font-weight: 500;
  color: var(--default-symbols-secondary);
}

.competition-title {
  padding-bottom: 8px;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
}

.competition-desc {
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.01em;
}

.competition-desc span:first-child {
  margin-right: 4px;
}

.competition-item-divider {
  background-color: #ededed;
  align-self: center;
  height: 1px;
  width: 90%;
}

.headings-info {
  margin-top: 8px;
}

.expand-headings,
.hide-headings {
  display: flex;
  cursor: pointer;
  align-items: center;
  padding-top: 5px;
  padding-bottom: 10px;
}

.hide-headings {
  margin-top: 10px;
}

.expand-headings-text,
.hide-headings-text {
  font-size: 13px;
  font-weight: 800;
  line-height: 20px;
  letter-spacing: -0.01em;
  cursor: pointer
}

.expand-headings:hover .expand-headings-text {
  color: #6a71f6;
}

.hide-headings:hover .hide-headings-text {
  color: #6a71f6;
}
