.article-keywords-table-header {
    display: flex;
    align-items: center;
    color: var(--default-symbols-primary);
    padding-bottom: 8px;
}

.article-keywords-table-header.loading .search-icon {
    opacity: 0.2;
}

.column-header {
    /*padding-inline: 8px;*/
}

.input-search {
    padding-left: 20px;
    border: none;
    outline: none;
}

.input-search--keyword {
    position: relative;
}

.column-header,
.input-search,
.input-search::placeholder {
    color: var(--default-symbols-primary);
    font-size: 13px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: -0.01em;
    text-align: left;
    background: transparent;
}

.search-icon {
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    left: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
}

.article-keywords-table-header .track-checkbox {
    position: absolute;
    left: -8px;
}