.publish-article-modal .app-modal__custom-content {
    display: grid;
    gap: 36px;
}
.publish-article-modal input {
    background: var(--default-surfaces-light);
    border: 2px solid transparent;
}

.publish-article-modal .app-modal__content {
    width: 100%;
    max-width: 538px;
    padding: 24px;
}
.publish-article-modal .app-modal-icon {
    width: 120px;
    height: 120px;
}

.publish-article-modal .app-modal-title {
    font-size: 24px;
    font-weight: 800;
    line-height: 30px;
    letter-spacing: -0.02em;
    margin-bottom: 4px;
}

.publish-article-modal .app-modal-text {
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.01em;
    margin-bottom: 16px;
}

.publish-article-modal__footer {
    display: grid;
    gap: 12px;
}

.publish-article-modal__footer button {
    border-radius: 20px;
    background: var(--default-surfaces-anchor);
    font-size: 13px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: -0.01em;
    color: var(--inverted-symbols-primary);
    padding: 12px;
    cursor: pointer;
    border: none;
}
.publish-article-modal__footer button[disabled] {
    background: var(--default-surfaces-dark);
    color: var(--inverted-symbols-secondary);
}
.publish-article-modal__footer {
    font-size: 13px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: -0.01em;
}
.publish-article-modal__content {
    display: grid;
    gap: 16px;
}
.publish-article-modal__content ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: grid;
    gap: 8px;
}

.publish-article-modal__content ul li,
.publish-article-modal__content ul li .checkbox-label-text {
    font-size: 13px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: -0.01em;
}
.publish-article-modal__content ul li {
    display: grid;
    gap: 8px;
    border: 1px solid var(--default-borders-light);
    border-radius: 12px;
    padding: 12px 8px
}
span.tracking-info__status {
    display: flex;
    align-items: center;
}
.publish-article-modal__content ul li button {
    background: var(--minor-surfaces-anchor);
    border-radius: 50px;
    color: var(--inverted-symbols-primary);
    padding: 6px 8px;
    cursor: pointer;
    font-size: 11px;
    font-weight: 700;
    line-height: 14px;
    border: none;
}
.publish-article-modal__content ul li button.disabled {
    background: var(--default-surfaces-dark);
    color: var(--inverted-symbols-secondary);
    cursor: not-allowed;
}
.publish-article-modal__content ul li .tracking-info__details {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.publish-article-modal__content ul li .tracking-info__status {
    display: flex;
    align-items: center;
    gap: 4px;
}
.publish-article-modal__content ul li .tracking-info__status > svg {
    width: 10px;
    /* height: 16px; */
    margin: 0 3px;
}
.tracking-info__actions {
    display: flex;
    justify-content: flex-start;
}
.publish-article-modal__content ul li .tracking-info__details path {
    fill: var(--default-surfaces-anchor);
}
.publish-article-modal__content ul li.connected .tracking-info__status > svg path {
    fill: var(--positive-symbols-primary);
}
.publish-article-modal__content ul li.disconnected {
    color: var(--danger-symbols-primary);
}
.publish-article-modal__content ul li.disconnected .tracking-info__status > svg path {
    fill: var(--danger-symbols-primary);
}
.publish-article-modal__info {
    color: var(--default-symbols-primary);
    font-size: 13px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: -0.01em;
    display: flex;
    align-items: center;
    gap: 4px;
    margin-top: 20px;
}

.publish-article-modal__info path {
    fill: var(--default-symbols-secondary);
}

.publish-article-modal .info-tooltip {
    height: 16px;
    width: 16px;
}
.publish-article-modal .info-tooltip svg {
    width: 100%;
    height: 100%;
}
.publish-article-modal__content ul li .info-tooltip path {
    fill: var(--default-symbols-secondary);
}
