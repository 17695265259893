.modal-header {
    color: var(--default-symbols-primary);
}

.modal-header_welcome {
    font-size: 20px;
    font-weight: 800;
    line-height: 24px;
    letter-spacing: -0.02em;
}

.modal-header_title {
    font-size: 62px;
    font-weight: 900;
    line-height: 68px;
    letter-spacing: -0.02em;
    margin-bottom: 24px;
}

.modal-header_subtitle {
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: -0.02em;

}

.modal-header_subtitle--colored {
    color: var(--night-symbols-primary);
}

.modal-header_close {
    position: absolute;
    right: 16px;
    top: 16px;
    font-size: 0;
    padding: 8px;
    cursor: pointer;
}