.modal-how-it-works {
    border-left: 1px solid var(--default-borders-light);
    padding-left: 16px;
}

.modal-how-it-works_boxes {
    margin-top: 16px;
    display: grid;
    row-gap: 16px;
}

.modal-how-it-works_title {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    color: var(--default-symbols-primary);
}