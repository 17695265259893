.editor-content-info {
  margin-bottom: 24px;
}

.editor-content-info__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0;
}

.editor-content-info .toggle-styling {
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: -0.01em;
  color: var(--default-symbols-secondary);
  text-transform: lowercase;
  cursor: pointer;
}

.editor-content-info .toggle-styling:first-letter {
  text-transform: uppercase;
}

.toggle-styling svg {
  width: 12px;
  margin-left: 6px;
}

.toggle-styling path {
  fill: var(--default-symbols-secondary);
}