.save-options-container{
    background: var(--default-surfaces-base);
    box-shadow: 0px 20px 60px 0px rgba(0, 0, 0, 0.10);
    border-radius: 12px;
    padding: 8px;
    position: absolute;
    z-index: 1;
    width: 254px;
    right: 0;
    margin-top: 8px;
}

.save-options-container .option-item{
    padding: 8px;
    color: var(--default-symbols-primary);
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px; /* 123.077% */
    letter-spacing: -0.13px;
    cursor: pointer
}

.save-options-container .option-item:hover{
    background: var(--default-surfaces-light)
}