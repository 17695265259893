.article-keywords-table-row {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.article-keywords-table-column{
    display: flex;
    align-items: center
}
.article-keywords-table-column div:not(.track-checkbox) > svg {
    margin-left: 4px
}
