.default-settings-sidebar__wrapper {
    display: flex;
    align-items: center;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 3;
}

.default-settings-sidebar {
    width: 100%;
    max-width: 470px;
    height: 100%;
    background: var(--default-surfaces-base);
    z-index: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.default-settings-sidebar__content {
    padding: 0 24px 24px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: auto;
}