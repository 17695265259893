.previous-article {
    display: flex;
    align-items: center;
    cursor: pointer;
}
.previous-article__container{
    color: var(--default-symbols-secondary, rgba(23, 23, 23, 0.5));
    margin-top: 24px;
    display: flex;
}

.previous-article__container svg{
    height:18px;
    width: 18px;
    margin-left: 4px;
}

.previous-article__container svg path{
    fill-opacity : 1;
    fill: var(--default-symbols-secondary);
}

/*@tibi does this need to be removed?*/
.previous-article__container .single-text{
    color: var(--default-symbols-primary);
    font-weight: 500
}

.previous-article__container p{
    color: var(--default-symbols-secondary);
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
}