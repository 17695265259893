.link-input-url  input {
  margin-right: 15px;
  border: none;
  background: none;
  outline: none;
  flex-grow: 1;
  padding: 0 ;
  width: 100%
}
.link-input-url{
   color: var(--color-light-text);
  border-radius: var(--m, 12px);
  background: var(--default-surfaces-light, rgba(23, 23, 23, 0.05));
  padding: 12px 16px;
  width: 404px;
  display: flex;
  justify-content: space-between;
}
.insert-link-btn {
  padding: var(--M, 8px) var(--L, 16px);
  border-radius: 20px;
  background: var(--default-surfaces-anchor, #171717);
  outline: none;
  color: var(--inverted-symbols-primary, #FFF);
  border: none;
  cursor: pointer
}
.insert-link-btn.link-disabled{
  color: var(--inverted-symbols-secondary, rgba(255, 255, 255, 0.50));
  background: var(--default-surfaces-dark, rgba(23, 23, 23, 0.30));
  pointer-events: none;
}
.hyperlink-opener {
  margin-top: 60px;
}

.hyperlink-opener .MuiPaper-root{
  padding: 16px;
  border-radius: var(--m, 12px) !important;
  background: var(--default-surfaces-base, #FFF) !important;
  box-shadow: 0px 20px 60px 0px rgba(0, 0, 0, 0.10) !important;
}

.link-input-url  input::placeholder{
  color: var(--default-symbols-secondary, rgba(23, 23, 23, 0.50));
  color: var(--default-symbols-secondary, rgba(23, 23, 23, 0.50));
  text-overflow: ellipsis;
  /* Info/Identity/Bold */
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px; /* 120% */
  letter-spacing: -0.15px;
}

.link-input-url label{
  color: var(--default-symbols-primary, #171717);
/* Info/Secondary/Extrabold */
font-family: Inter;
font-size: 11px;
font-style: normal;
font-weight: 800;
line-height: 14px; /* 127.273% */
display: block
}
