.ai-card-links {
    margin-top: 12px;
    display: grid;
    row-gap: 8px;
    padding-top: 4px;
}

.ai-card-link-item {
    background: transparent;
    border-radius: 12px;
    padding: 12px 64px 12px 12px;
    position: relative;
    background: var(--night-symbols-primary);
}

.delete-link {
    position: absolute;
    top: 12px;
    right: 12px;
    z-index: 1;
    height: 28px;
    width: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--night-symbols-alternative);
    cursor: pointer;
    border-radius: 50px;
}
.delete-link path {
    fill: var(--inverted-symbols-primary);
}

.link-item__url:hover {
    text-decoration: underline;
}

.ai-card-link-item__url {
    align-items: center;
    margin-bottom: 6px;
    max-width: 100%;
    display: grid;
    grid-template-columns: 16px auto;
    gap: 6px;
    cursor: pointer;
}

.link-item__order-number {
    width: 16px;
    min-width: 16px;
    height: 16px;
    color: var(--night-surfaces-anchor);
    background: var(--inverted-symbols-primary);
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    line-height: 1;
}

.link-item__url {
    font-size: 13px;
    font-weight: 800;
    line-height: 16px;
    letter-spacing: -0.01em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
}

.ai-card-link-item__title {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 6px;
}

.link-item__description {
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.01em;
}

.ai-card-links__input {
    margin-top: 12px;
}