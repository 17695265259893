.loading-block {
	 color: transparent;
	 background: var(--default-surfaces-light);
	 opacity: 1;
	 display: inline-block;
	 vertical-align: middle;
	 animation: pulse 3s infinite ease;
}
 .loading-block--rounded {
	 border-radius: 32px;
}
 @keyframes pulse {
	 0% {
		 opacity: 1;
	}
	 50% {
		 opacity: 0.5;
	}
	 100% {
		 opacity: 1;
	}
}
 