.writing-style-overview {
    border-radius: 8px;
    padding: 16px;
    width: 100%;
    column-gap: 24px;
    margin-top: 12px;
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
    grid-template-areas:
        "title action"
        "options options";
}

.writing-style-overview__info {
    grid-area: title;
    align-self: center;
    font-size: 15px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: -0.01em;
    text-align: left;
}

.writing-style-overview__description {
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.01em;
    margin-top: 2px;
}

.writing-style-overview__title {
    display: flex;
    align-items: center;
}

.writing-style-overview__info span {
    margin-right: 4px;
}

.writing-style-overview__info span,
.writing-style-overview__info svg {
    width: 14px;
    height: 14px;
}

.writing-style-overview__configure {
    grid-area: action;
    align-self: center;
    justify-self: end;
    padding: 8px 16px;
    border-radius: 50px;
    cursor: pointer;
    font-size: 13px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: -0.01em;
}

.writing-style-overview__options {
    grid-area: options;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 16px;
    margin-top: 24px;
}

.writing-style-overview__options .option-selected {
    text-align: left;
    overflow: hidden;
}

.writing-style-overview__options .option-label {
    font-size: 11px;
    font-weight: 800;
    line-height: 14px;
}

.writing-style-overview__options .option-value {
    font-size: 15px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: -0.01em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
}

.writing-style-overview__options .option-value:first-letter {
    text-transform: uppercase;
}

/*brand style colors*/
.writing-style-overview.brand {
    background: var(--night-symbols-primary);
}

.brand .writing-style-overview__info {
    color: var(--inverted-symbols-primary);
}


.brand .writing-style-overview__info path {
    fill: var(--inverted-symbols-primary);
}

.brand .writing-style-overview__configure {
    background: var(--night-symbols-alternative);
    color: var(--inverted-symbols-primary);
}

.brand .writing-style-overview__options .option-selected {
    color: var(--inverted-symbols-primary);
}

.brand .writing-style-overview__options .option-label {
    color: var(--inverted-symbols-secondary);
}

/*white style colors*/
.writing-style-overview.white {
    background: var(--default-surfaces-light);
}

.white .writing-style-overview__info {
    color: var(--default-symbols-primary);
}

.white .writing-style-overview__configure {
    background: var(--default-surfaces-base);
    color: var(--default-symbols-primary);
}

.white .writing-style-overview__options .option-selected {
    color: var(--default-symbols-primary);
}

.white .writing-style-overview__options .option-label {
    color: var(--default-symbols-secondary);
}