.writer-style {
}

.writer-style__options {
    display: grid;
    gap: 8px;
    border-radius: 12px;
    margin-bottom: 74px;
}

.writer-style__options .loading-block {
    border-radius: 12px;
}

.writer-style__actions {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px;
    padding: 16px 24px;
    border-top: 1px solid var(--default-borders-light);
    text-align: center;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: var(--default-surfaces-base);
}

.writer-style__button {
    padding: 8px;
    font-size: 13px;
    cursor: pointer;
    font-weight: 700;
    line-height: 16px;
    border-radius: 50px;
    letter-spacing: -0.01em;
    color: var(--default-symbols-primary);
    background: var(--default-surfaces-light);
}

.writer-style__button.save {
    color: var(--inverted-symbols-primary);
    background: var(--default-surfaces-anchor);
}

.writer-style__name {
    margin-top: 8px;
    margin-bottom: 12px;
}

.writer-style__name span {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
}

.writer-style__name .loading-block {
    border-radius: 6px;
}
.writer-style__name svg {
    margin-right: 8px;
}

.writer-style__name svg path {
    fill: var(--default-symbols-secondary)
}

.writer-style__article-sample {
    margin-top: 16px;
}

.article-sample__label {
    font-size: 15px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: -0.01em;
    margin-bottom: 8px;
}

.writer-style__article-sample .input__wrapper input {
    background: var(--default-surfaces-light);
    border: 2px solid transparent;
}
.writer-style__article-sample .input__wrapper:not(.input__wrapper--loading) input:focus {
    border-color: var(--hero-borders-anchor);
    background: var(--default-surfaces-base)
}
