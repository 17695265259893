.prompt-placeholder {
    font-size: 15px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: -0.01em;
    color: var(--default-symbols-secondary);
    position: absolute;
    left: 0;
    top: 26px;
    bottom: 36px;
    overflow: hidden;
    padding: 12px 16px;
    border: 2px solid transparent;
    pointer-events: none;
}

.prompt-placeholder ul {
    line-height: 1.6;
}