.competition {
  display: flex;
  flex-direction: column;
  font-family: Inter;
  color: var(--default-symbols-primary);
  background: var(--default-surfaces-base);
  width: 470px;
  position: fixed;
  right: -470px;
  top: 0;
  bottom: 0;
  z-index: 2;
  opacity: 0;
  transition: 0.5s ease;
}

.competition.open {
  right: 0;
  opacity: 1;
}

.competiton-head {
  padding: 24px 24px 0;
  position: relative;
}

.competition-close-icon {
  position: absolute;
  cursor: pointer;
  right: 24px;
  top: 24px;
  height: 18px;
  width: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.competition h3 {
  font-size: 32px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: -0.02em;
  margin-bottom: 16px;
}

.competition-sub {
  font-size: 15px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.01em;
  margin-bottom: 8px;
}

.competition-content {
  padding: 0 24px 24px;
}

.competition-content .app-tab-selector {
  margin-bottom: 16px;
}

.top-numbers {
  display: flex;
  gap: 8px;
  padding-top: 8px;
  padding-bottom: 32px;
}

.competition-numbers {
  font-size: 32px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: -0.02em;
  padding: 2px 8px 0;
}
.competition-numbers-info {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
}

.competition-numbers-label {
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -0.01em;
}