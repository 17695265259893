.spellbinding-suggestions {
    margin-top: 24px;
}
.spellbinding-suggestions__title {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 8px;
    margin-left: 0;
}

.pagination-item {
    height: 32px;
    width: 32px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: -0.01em;
    cursor: pointer;
}
.pagination-item.disabled {
    cursor: not-allowed;
}
.pagination-item.disabled path {
    fill: var(--default-symbols-secondary)
}
.pagination-item.ellipsis {
    flex-grow: 1;
    align-items: center;
    display: flex;
    cursor: default;
}
.pagination-item--plus svg {
    width: 12px;
    height: 12px;
}
.pagination-item--loading {
    cursor: default;
}

.pagination-item.active {
    background: var(--default-surfaces-light);
}

.spellbinding-suggestions__pagination {
    display: flex;
    align-items: center;
    column-gap: 6px;
    margin-top: 8px;
}

.spellbinding-suggestions__loading-info path {
    fill: var(--default-symbols-secondary);
}
.spellbinding-suggestions__loading-info {
    color: var(--default-symbols-secondary);
    font-size: 11px;
    font-weight: 700;
    line-height: 32px;
    text-align: left;
    margin-top: 8px;
    display: flex;
    align-items: center;
    gap: 4px;
}


.spellbinding-suggestions__error {
    color: var(--danger-symbols-primary);
    font-size: 11px;
    font-weight: 700;
    line-height: 14px;
    text-align: left;
    margin-top: 4px;
}
.spellbinding-suggestions__error a {
    color: inherit;
    text-decoration: underline;
    white-space: nowrap;
}