.app-select {
    gap: 16px;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    padding: 12px 16px;
    background: var(--inverted-surfaces-light);
    border-radius: 12px;
    cursor: pointer;
    position: relative;
}

.app-select__trigger {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 1;
}

.app-select__icon svg {
    height: 12px;
    width: 12px;
}
.app-select__icon path {
    fill: var(--inverted-symbols-secondary);
}

.app-select__inner {
    text-align: left;
    display: grid;
    gap: 2px;
}

.app-select__label {
    font-size: 11px;
    font-weight: 800;
    line-height: 14px;
    color: var(--inverted-symbols-primary)
}

/** SELECT **/
.app-select__select {
    position: unset !important;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    height: 16px;
}

.app-select__select .app-select__value-container {
    padding: 0;
    font-size: 0;
}
.app-select__select .app-select__control {
    background: transparent;
    border: none;
    min-height: 0;
    box-shadow: none;
    /*outline: none;*/
}

.app-select__select .app-select__menu {
    margin: 0;
    left: 0;
    right: 0;
    background: var(--inverted-surfaces-anchor);
    padding: 8px;
    z-index: 2;
    box-shadow: 0px 20px 60px 0px rgba(0, 0, 0, 0.1);
}
.app-select__select .app-select__option {
    font-size: 13px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: -0.01em;
    border-radius: 6px;
    cursor: pointer;
    color: var(--default-symbols-primary);
    padding: 8px;
}
.app-select__select .app-select__option.app-select__option--is-focused,
.app-select__select .app-select__option:hover {
    background: var(--default-surfaces-light);
    color: var(--default-symbols-primary);
}
.app-select__select .app-select__option.app-select__option--is-selected {
    background: var(--default-surfaces-anchor);
    color: var(--inverted-symbols-primary);
}
.app-select__select .app-select__multi-value {
    background: transparent;
    margin: 0;
}
.app-select__select .app-select__value-container--has-value,
.app-select__select .app-select__single-value,
.app-select__select .app-select__multi-value__label,
.app-select__select .app-select__placeholder {
    font-size: 13px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: -0.01em;
    padding: 0;
    border-radius: 0;
    color: var(--inverted-symbols-primary);
    margin: 0;
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    /*outline: none;*/
}
.app-select__select .app-select__single-value:first-letter,
.app-select__select .app-select__multi-value__label:first-letter {
    text-transform: capitalize;
}
.app-select.grey .app-select__select .app-select__value-container--has-value,
.app-select.grey .app-select__select .app-select__single-value,
.app-select.grey .app-select__select .app-select__multi-value__label,
.app-select.grey .app-select__select .app-select__placeholder {
    color: var(--default-symbols-primary)
}

.app-select__select .app-select__multi-value:not(:first-child) .app-select__multi-value__label:before {
    content: ", ";
}
.app-select__select .app-select__multi-value .app-select__multi-value__remove,
.app-select__select .app-select__indicators {
    display: none;
}

/** END OF SELECT **/


.app-select.grey {
    background: var(--default-surfaces-light);
}
.app-select.grey .app-select__label {
    color: var(--default-symbols-primary)
}

.app-select.grey .app-select__icon path {
    fill: var(--default-symbols-secondary);
}
