.spellbinding-suggestions-item {
    padding: 8px;
    border-radius: 12px;
    display: grid;
    align-items: center;
    column-gap: 4px;
    grid-template-columns: auto auto 1fr;
    max-width: 100%;
}
.spellbinding-suggestions-item.disabled {
    opacity: 0.25;
    cursor: default;
}

.spellbinding-suggestions-item span {
    line-height: 16px;
}

.spellbinding-suggestions-item:not(.loading):not(.disabled):hover {
    background: var(--night-surfaces-ghost-hv);
    cursor: pointer;
}

.spellbinding-suggestions-item .loading-block {
    border-radius: 4px;
    background: var(--night-surfaces-ghost-hv);
}

.spellbinding-suggestions-item__topic {
    font-size: 13px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: -0.01em;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.spellbinding-suggestions-item__traffic {
    font-size: 13px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: -0.01em;
    color: var(--default-symbols-secondary)
}

.spellbinding-suggestions-item svg {
    height: 13px;
    width: 13px;
}

.spellbinding-suggestions-item path {
    fill: var(--default-symbols-secondary)
}