.app-modal {
  position: fixed;
  z-index: 20;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  color: var(--default-symbols-primary);
}

.app-modal__content {
  background: var(--default-surfaces-base);
  z-index: 1;
  border-radius: 12px;
  position: relative;
  padding: 24px;
  width: 400px;
}

.app-modal-icon {
  width: 54px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
}
.app-modal-icon svg {
  width: 100%;
  height: 100%;
}
.app-modal-title {
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: -0.02em;
  margin-bottom: 8px;
}

.app-modal-text {
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.01em;
}

.app-modal-text:not(:last-child) {
  margin-bottom: 12px;
}

.app-modal-text ul {
  padding-left: 20px
}

.app-modal__buttons-wrapper {
  margin-top: 36px;
  display: flex;
  align-items: center;
  gap: 16px;
}

.app-modal__info-text {
  color: var(--default-symbols-secondary);
  font-size: 11px;
  font-weight: 500;
  line-height: 14px;
  text-align: left;
  margin-top: 8px;
}

.app-modal-button {
  background: var(--default-surfaces-anchor);
  color: var(--inverted-symbols-primary);
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: -0.01em;
  border-radius: 50px;
  text-align: center;
  padding: 8px;
  cursor: pointer;
  flex-grow : 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.app-modal-button.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.app-modal-button svg {
  height: 14px;
  width: 14px;
  margin-right: 4px;
}

.app-modal-button.big-button {
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: -0.02em;
  border-radius: 20px;
  width: 100%;
  padding: 12px;
}
.app-modal-button--secondary {
  color: var(--default-symbols-primary);
  background: var(--default-surfaces-ghost-hv);
}

.app-modal__header{
  display: flex;
  justify-content: space-between;
}

.app-modal__header svg{
  cursor: pointer;
  width: 100%;
  height: 100%;
  fill: var(--default-symbols-primary);
}
.app-modal .close-icon__wrapper {
  width: 20px;
  height: 20px;
  margin-left: auto;
}