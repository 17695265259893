.articles-sidebar-title__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 8px 4px;
    margin-bottom: 16px;
}
.articles-sidebar-title__actions {
    display: flex;
    align-items: center;
    gap: 16px;
}
.articles-sidebar-header {
    padding: 16px;
}

.articles-sidebar-title {
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: -0.02em;
    color: var(--default-symbols-primary);
}

.articles-sidebar-close {
    font-size: 0;
    padding: 4px;
    cursor: pointer;
}

.articles-sidebar-subheader {
    padding: 0 8px;
}

.articles-sidebar-input {
    position: relative;
}

.articles-sidebar-input-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 16px;
    padding: 2px;
    font-size: 0;
}

.articles-sidebar-input input {
    background: var(--default-surfaces-light);
    outline: none;
    border: none;
    border-radius: 12px;
    width: 100%;
    font-size: 15px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: -0.01em;
    color: var(--default-symbols-secondary);
    padding: 12px 16px 12px 36px;
}

.articles-sidebar-input svg path {
    fill: var(--default-symbols-secondary);
}

.open-published {
    background: var(--default-surfaces-light);
    border-radius: 20px;
    padding: 8px 16px;
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 13px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: -0.01em;
    cursor: pointer;
}