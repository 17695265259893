.new-article-modal__content.new-article-modal__content--new-user .modal-start-article {
    max-width: 100%;
}
.modal-start-article--disabled {
    opacity: 0.4;
    cursor: not-allowed;
}
.modal-start-article--disabled input {
    pointer-events: none;
}
.start-article__label {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 8px;
}

.start-article__label strong {
    font-weight: 700;
}
.start-article__input-wrapper {
    position: relative;
}
.start-article__input-wrapper svg {
    height: 16px;
    width: 16px;
}
.start-article__input {
    background: var(--default-surfaces-light);
    color: var(--default-symbols-secondary);
    padding: 12px 40px 12px 16px;
    border-radius: 12px;
    border: 2px solid transparent;
    font-size: 15px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: -0.01em;
    text-align: left;
    width: 100%;
}

.start-article__input-label {
    font-size: 11px;
    font-weight: 800;
    line-height: 14px;
    position: absolute;
    left: 16px;
    top: 12px;
}

.modal-start-article--active .start-article__input,
.start-article__input:focus {
    background: var(--default-surfaces-base);
    border-color: var(--hero-borders-anchor);
    color: var(--default-symbols-primary);
    outline: none;
}

.modal-start-article--error .start-article__input {
    background: var(--negative-surfaces-light);
    border-color: transparent;
    outline: none;
}
.modal-start-article--loading path {
    fill: var(--default-symbols-primary)
}

.modal-start-article--active:not(.modal-start-article--loading):not(.modal-start-article--no-articles-left) .start-article__input-action {
    opacity: 0.5;
    cursor: pointer;
}

.spinner-icon path {
    animation: rotate 2s linear infinite;
    transform-origin: center center;
}

.start-article__input-action {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    padding: 12px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.start-article__loading-info {
    color: var(--default-symbols-secondary);
    font-size: 11px;
    font-weight: 700;
    line-height: 14px;
    text-align: left;
    margin-top: 4px;
}
.start-article__error {
    color: var(--danger-symbols-primary);
    font-size: 11px;
    font-weight: 700;
    line-height: 14px;
    text-align: left;
    margin-top: 4px;
}
.start-article__error a {
    color: inherit;
    text-decoration: underline;
    white-space: nowrap;
}
.modal-start-article--no-articles-left .start-article__input-action {
    cursor: default;
    opacity: 0.25;
}