@mixin display-xxl-black {
    font-size: 96px;
    font-weight: 900;
    line-height: 88px;
    letter-spacing: -0.03em;
}

@mixin display-xl-black {
    font-size: 62px;
    font-weight: 900;
    line-height: 68px;
    letter-spacing: -0.02em;
}

@mixin display-xl-extrabold {
    font-size: 62px;
    font-weight: 800;
    line-height: 68px;
    letter-spacing: -0.02em;
}

@mixin display-xl-bold {
    font-size: 62px;
    font-weight: 700;
    line-height: 68px;
    letter-spacing: -0.02em;
}

@mixin display-xl-normal {
    font-size: 62px;
    font-weight: 500;
    line-height: 68px;
    letter-spacing: -0.02em;
}

@mixin display-l-extrabold {
    font-size: 32px;
    font-weight: 800;
    line-height: 36px;
    letter-spacing: -0.02em;
}

@mixin display-l-bold {
    font-size: 32px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: -0.02em;
}

@mixin display-l-normal {
    font-size: 32px;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: -0.02em;
}

@mixin display-m-extrabold {
    font-size: 24px;
    font-weight: 800;
    line-height: 30px;
    letter-spacing: -0.02em;
}

@mixin display-m-bold {
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: -0.02em;
}

@mixin display-m-normal {
    font-size: 24px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0;
}

@mixin display-s-extrabold {
    font-size: 20px;
    font-weight: 800;
    line-height: 24px;
    letter-spacing: -0.02em;
}

@mixin display-s-bold {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0;
}

@mixin display-s-normal {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0;
}

@mixin display-identity-extrabold {
    font-size: 15px;
    font-weight: 800;
    line-height: 22px;
    letter-spacing: -0.01em;
}

@mixin display-identity-bold {
    font-size: 15px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: -0.01em;
}

@mixin display-identity-normal {
    font-size: 15px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: -0.01em;
}

@mixin display-primary-black {
    font-size: 13px;
    font-weight: 900;
    line-height: 20px;
    letter-spacing: -0.01em;
}

@mixin display-primary-extrabold {
    font-size: 13px;
    font-weight: 800;
    line-height: 20px;
    letter-spacing: -0.01em;
}

@mixin display-primary-bold {
    font-size: 13px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: -0.01em;
}

@mixin display-primary-normal {
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.01em;
}

@mixin display-primary-text {
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.01em;
}

@mixin display-secondary-black {
    font-size: 11px;
    font-weight: 900;
    line-height: 14px;
    letter-spacing: 0;
}

@mixin display-secondary-extrabold {
    font-size: 11px;
    font-weight: 800;
    line-height: 14px;
    letter-spacing: 0;
}

@mixin display-secondary-bold {
    font-size: 11px;
    font-weight: 700;
    line-height: 14px;
    letter-spacing: 0;
}

@mixin display-secondary-normal {
    font-size: 11px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0;
}

@mixin info-identity-extrabold {
    font-size: 15px;
    font-weight: 800;
    line-height: 18px;
    letter-spacing: -0.01em;
}

@mixin info-identity-bold {
    font-size: 15px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: -0.01em;
}

@mixin info-identity-normal {
    font-size: 15px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: -0.01em;
}

@mixin info-primary-black {
    font-size: 13px;
    font-weight: 900;
    line-height: 16px;
    letter-spacing: -0.01em;
}

@mixin info-primary-extrabold {
    font-size: 13px;
    font-weight: 800;
    line-height: 16px;
    letter-spacing: -0.01em;
}

@mixin info-primary-bold {
    font-size: 13px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: -0.01em;
}

@mixin info-primary-normal {
    font-size: 13px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: -0.01em;
}

@mixin info-primary-text {
    font-size: 13px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: -0.01em;
}

@mixin info-secondary-black {
    font-size: 11px;
    font-weight: 900;
    line-height: 14px;
    letter-spacing: 0;
}

@mixin info-secondary-extrabold {
    font-size: 11px;
    font-weight: 800;
    line-height: 14px;
    letter-spacing: 0;
}

@mixin info-secondary-bold {
    font-size: 11px;
    font-weight: 700;
    line-height: 14px;
    letter-spacing: 0;
}

@mixin info-secondary-normal {
    font-size: 11px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0;
}




.facelift,
.storybook-typography-selector {
    .display-xxl-black {
        @include display-xxl-black;
    }

    .display-xl-black {
        @include display-xl-black;
    }

    .display-xl-extrabold {
        @include display-xl-extrabold;
    }

    .display-xl-bold {
        @include display-xl-bold;
    }

    .display-xl-normal {
        @include display-xl-normal;
    }

    .display-l-extrabold {
        @include display-l-extrabold;
    }

    .display-l-bold {
        @include display-l-bold;
    }

    .display-l-normal {
        @include display-l-normal;
    }

    .display-m-extrabold {
        @include display-m-extrabold;
    }

    .display-m-bold {
        @include display-m-bold;
    }

    .display-m-normal {
        @include display-m-normal;
    }

    .display-s-extrabold {
        @include display-s-extrabold;
    }

    .display-s-bold {
        @include display-s-bold;
    }

    .display-s-normal {
        @include display-s-normal;
    }

    .display-identity-extrabold {
        @include display-identity-extrabold;
    }

    .display-identity-bold {
        @include display-identity-bold;
    }

    .display-identity-normal {
        @include display-identity-normal;
    }

    .display-primary-black {
        @include display-primary-black;
    }

    .display-primary-extrabold {
        @include display-primary-extrabold;
    }

    .display-primary-bold {
        @include display-primary-bold;
    }

    .display-primary-normal {
        @include display-primary-normal;
    }

    .display-primary-text {
        @include display-primary-text;
    }

    .display-secondary-black {
        @include display-secondary-black;
    }

    .display-secondary-extrabold {
        @include display-secondary-extrabold;
    }

    .display-secondary-bold {
        @include display-secondary-bold;
    }

    .display-secondary-normal {
        @include display-secondary-normal;
    }

    .info-identity-extrabold {
        @include info-identity-extrabold;
    }

    .info-identity-bold {
        @include info-identity-bold;
    }

    .info-identity-normal {
        @include info-identity-normal;
    }

    .info-primary-black {
        @include info-primary-black;
    }

    .info-primary-extrabold {
        @include info-primary-extrabold;
    }

    .info-primary-bold {
        @include info-primary-bold;
    }

    .info-primary-normal {
        @include info-primary-normal;
    }

    .info-primary-text {
        @include info-primary-text;
    }

    .info-secondary-black {
        @include info-secondary-black;
    }

    .info-secondary-extrabold {
        @include info-secondary-extrabold;
    }

    .info-secondary-bold {
        @include info-secondary-bold;
    }

    .info-secondary-normal {
        @include info-secondary-normal;
    }

}

.page-anchor {
    @include display-m-bold;
    padding: 8px;
    margin: 0;
}

.page-primary {
    @include display-m-normal;
    padding: 8px;
    margin: 0;
}

.section-anchor {
    @include display-s-bold;
    padding: 8px;
    margin: 0;
}

.section-primary {
    @include display-s-normal;
    padding: 8px;
    margin: 0;
}

.identity-anchor {
    @include info-identity-bold;
    padding: 8px;
    margin: 0;
}

.identity-primary {
    @include info-identity-normal;
    padding: 8px;
    margin: 0;
}

.default-key {
    @include info-primary-extrabold;
    padding: 8px;
    margin: 0;
}

.default-anchor {
    @include info-primary-bold;
    padding: 8px;
    margin: 0;
}

.default-primary {
    @include info-primary-normal;
    padding: 8px;
    margin: 0
}

.default-text {
    @include info-primary-normal;
    padding: 8px;
    margin: 0
}

.highlight-anchor {
    @include display-l-bold;
    padding: 8px;
    margin: 0 0 4px;
}

.micro-anchor {
    @include info-secondary-bold;
    padding: 8px;
    margin: 0;
}
.micro-primary {
    @include info-secondary-bold;
    padding: 8px;
    margin: 0;
}

// typography

// heading
.text-heading-1,
.text-heading-2,
.text-heading-3,
.text-heading-4,
.text-heading-5 {
  font-style: normal;
  font-weight: 800;
  line-height: 130%;
  color: var(--gray-dark);
}

.text-heading-1 {
  font-size: 24px;
  letter-spacing: -0.02em;
}

.text-heading-2 {
  font-size: 20px;
  letter-spacing: -0.02em;
}

.text-heading-3 {
  font-size: 18px;
  letter-spacing: -0.03em;
}

.text-heading-4 {
  font-size: 16px;
}

.text-heading-5 {
  font-size: 14px;
}

// body
.text-body-18,
.text-body-16,
.text-body-14,
.text-body-12 {
  font-style: normal;
  font-weight: 500;
  color: var(--gray-dark);
}

.text-body-18 {
  font-size: 18px;
  line-height: 130%;

  &.same-line-height {
    line-height: 18px;
  }
}

.text-body-16 {
  font-size: 16px;
  line-height: 130%;

  &.same-line-height {
    line-height: 16px;
  }
}

.text-body-14 {
  font-size: 14px;
  line-height: 140%;

  &.same-line-height {
    line-height: 14px;
  }
}

.text-body-12 {
  font-size: 12px;
  line-height: 140%;

  &.same-line-height {
    line-height: 12px;
  }
}

// labels
.text-label-12,
.text-label-10 {
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: var(--gray-dark);
}

.text-label-12 {
  font-size: 12px;
}

.text-label-10 {
  font-size: 10px;
}

a {
    color: inherit;
    text-decoration: underline;
}

// .loading-block {
//     color: transparent;
//     background: var(--layers-alive);
//     margin: 8px;

//     &.pill {
//         margin: 8px 4px;
//     }

//     &.--small-height {
//         height: 8px;
//     }
//     &.--medium-height {
//         height: 15px;
//     }
//     &.--large-height {
//         height: 24px;
//     }
//     &.--xlarge-height {
//         height: 32px;
//     }
//     &.--xxlarge-height {
//         height: 48px;
//     }

//     &.--small-width {
//         width: 8px;
//     }
//     &.--medium-width {
//         width: 15px;
//     }
//     &.--large-width {
//         width: 52px;
//     }
//     &.--xlarge-width {
//         width: 106px;
//     }
//     &.--xxlarge-width {
//         width: 248px;
//     }
// }