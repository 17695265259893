.article-keywords-table-group-header {
    background: #f3f3f3;
    border-radius: 12px;
    padding: 8px 16px 8px 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 14px;
    cursor: pointer;
}

.group-header-title svg {
    height: 8px;
    margin-left: 4px;
}
.group-header-title svg path {
    fill: var(--default-symbols-secondary)
}

.article-keywords-table-group-header .column--volume {
    padding-right: 16px;
}

.group-header-progress {
    margin-left: 8px;
}

.progress-icon--active,
.progress-icon {
    margin-left: 5px;
    font-size: 0;
    line-height: 1;
    width: 35px;
    text-align: right;
}

.progress-icon path{
    fill:var(--default-symbols-secondary)
}


.progress-icon--active path {
    fill: var(--hero-symbols-primary);
}