.new-article-modal {
    width: 100%;
    max-width: 960px;
    background: var(--default-surfaces-base);
    border-radius: 12px;
    padding: 36px 36px 12px;
    z-index: 1;
    position: relative;
    margin: auto;
}

.new-article-modal hr {
    margin: 24px 0;
    border-color: var(--default-surfaces-light, rgba(23, 23, 23, 0.05));
    border-width: 1px 0 0 0;
}
.new-article-modal__content {
    display: flex;
    align-items: flex-start;
    margin-bottom: 16px;
}
.modal__content--left {
    padding-right: 16px;
    max-width: 420px;
    width: 100%;
}
.new-article-modal__content.new-article-modal__content--new-user > * {
    max-width: 50%;
    width: 50%;
}