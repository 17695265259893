.articles-sidebar-item__wrapper {
    padding-inline: 16px;
}

.articles-sidebar-item {
    padding: 8px;
    margin-bottom: 8px;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    color: inherit;
}

.articles-sidebar-item:hover {
    background: var(--default-surfaces-ghost-hv);
}

.item--active .articles-sidebar-item {
    background: var(--default-surfaces-light);
}
.article-sidebar-item-info {
    flex-grow: 1;
    padding-right: 4px;
}

.article-item-topic {
    font-size: 15px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: -0.01em;
}

.article-item-topic_wrapper {
    gap: 4px;
    margin-bottom: 4px;
    display: flex;
    align-items: center;
}

.article-item-title {
    font-size: 13px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: -0.01em;
    margin-bottom: 4px;
}

.article-item-title.empty {
    opacity: 0.3
}

.article-item-details {
    padding-top: 4px;
    font-size: 11px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0em;
    display: flex;
    align-items: center;
    color: var(--default-symbols-secondary);
    gap: 12px;
}

.article-item-details > * {
    display: flex;
    align-items: center;
}
.article-item-fresh {
    background: var(--default-surfaces-light);
    border-radius: 50px;
    padding: 4px;
    color: var(--default-symbols-secondary);
    font-weight: 700;
    letter-spacing: -0.01em;
    text-transform: uppercase;
    height: 22px;
    min-width: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.article-item-details svg {
    width: 12px;
    height: 12px;
}
.article-item-details svg:not(:last-child) {
    margin-right: 4px;
}
.article-item-details path {
    fill: var(--default-symbols-secondary);
}

.article-item-author {
    color: var(--default-symbols-secondary);
}

.article-sidebar-item-score {
    width: 32px;
    min-width: 32px;
    height: 32px;
}

.article-sidebar-item-score_value {
    font-size: 11px;
    font-weight: 700;
    line-height: 14px;
    letter-spacing: 0em;
}

.article-item-topic .loading-block {
    height: 18px;
    width: 100px;
}

.article-item-add-traffic .loading-block {
    height: 18px;
    width: 50px;
}

.article-item-title .loading-block {
    height: 16px;
    width: 250px;
}

.article-item-date .loading-block {
    height: 14px;
    width: 30px;
}

.article-item-author .loading-block {
    height: 14px;
    width: 80px;
}