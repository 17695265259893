.default-settings-sidebar-header {
    display: flex;
    align-items: flex-start;
    gap: 16px;
    padding: 16px 24px 0;
}

.default-settings-sidebar-title__wrapper {
    display: grid;
    gap: 4px;
}

.default-settings-sidebar-title {
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: -0.02em;
    color: var(--default-symbols-primary);
}
.default-settings-sidebar-subtitle {
    font-size: 15px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: -0.01em;
}

.default-settings-sidebar-close {
    font-size: 0;
    padding: 4px;
    cursor: pointer;
}

.default-settings-sidebar-subheader {
    padding: 0 8px;
}

.default-settings-sidebar-input {
    position: relative;
}

.default-settings-sidebar-input-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 16px;
    padding: 2px;
    font-size: 0;
}

.default-settings-sidebar-input input {
    background: var(--default-surfaces-light);
    outline: none;
    border: none;
    border-radius: 12px;
    width: 100%;
    font-size: 15px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: -0.01em;
    color: var(--default-symbols-secondary);
    padding: 12px 16px 12px 36px;
}

.default-settings-sidebar-input svg path {
    fill: var(--default-symbols-secondary);
}