.loading-screen {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;
    background: #fff;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
}

.logo-container-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.logo-container-wrapper .logo-container {
    border: 3px solid #313438;
    border-radius: 16px;
    height: 120px;
    width: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.logo-container-wrapper .text-wrapper {
    margin-top: 16px;
    font-weight: 600;
    font-size: 24px;
    line-height: 117.52%;
    letter-spacing: -0.04em;
    color: #313438;
    position: relative;
}

.logo-container-wrapper .text-wrapper .loading-text {
    transition: 0.5s ease;
    opacity: 1;
}

.logo-container-wrapper .text-wrapper .transition-text {
    opacity: 0;
    position: absolute;
    top: 0;
    white-space: nowrap;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    transition: 0.5s ease;
    transition-delay: 0.5s;
}

.logo-container-wrapper .logo-wrapper {
    width: 75px;
    height: 44px;
    position: relative;
}

.logo-container-wrapper .logo-wrapper .logo {
    position: absolute;
    left: 0;
    right: 75px;
    top: 0;
    bottom: 0;
    overflow: hidden;
    animation: logo-animation 3s linear infinite;
}

.logo-container-wrapper .logo-wrapper .logo-cover {
    position: absolute;
    background: #fff;
    z-index: 2;
    left: 75px;
    right: 0;
    top: 0;
    bottom: 0;
    overflow: hidden;
    animation: logo-animation-2 3s linear infinite;
}

.logo-container-wrapper.small .logo-container {
    height: 60px;
    width: 60px;
}

.logo-container-wrapper.small .text-wrapper .loading-text {
    font-size: 16px;
}

.logo-container-wrapper.small .logo-wrapper {
    width: 37px;
    height: 22px;
}

@keyframes logo-animation {
    0% {
        right: 75px;
        left: 0;
    }
    33% {
        right: 0;
        left: 0;
    }
    66% {
        right: 0;
        left: 0;
    }
    99% {
        right: 0;
        left: 0;
    }
    100% {
        right: 75px;
        left: 0;
    }
}

@keyframes logo-animation-2 {
    0% {
        right: 75px;
        left: 0;
    }
    33% {
        right: 75px;
        left: 0;
    }
    66% {
        right: 75px;
        left: 0;
    }
    99% {
        right: 0;
        left: 0;
    }
    100% {
        right: 75px;
        left: 0;
    }
}

.show-transition-text .loading-text {
    opacity: 0;
}

.show-transition-text .transition-text {
    opacity: 1;
}
