@import "../_global-vars.css";

.label {
  font-weight: bold;
  margin-bottom: 0.3rem;
  font-size: 15px;
  color: var(--color-dark-text);
}

.react-select-container {
  color: var(--color-dark-text);
}

.react-select__control {
  min-height: 35px!important;
  border: solid 1px var(--color-inactive-input) !important;
  border-radius: 7px!important;
}

.react-select__control--is-focused {
  border: solid 1px var(--color-active-input) !important;
  box-shadow: none!important;
}

.react-select__multi-value__remove:hover {
  background-color: var(--color-active-input) !important;
  color: white!important;
}


.custom-select {
  position: relative;
}
.custom-select .label {
  position: absolute;
  left: 16px;
  top: 12px;
  z-index: 1;
  font-size: 11px;
  font-weight: 800;
  line-height: 14px;
  color: var(--default-symbols-primary);
}

.react-select__value-container > div {
  padding: 0;
  margin: 0;
  font-size: 15px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: -0.01em;
}

.react-select__indicator,
.react-select__value-container {
  padding: 0 !important;
}

span.react-select__indicator-separator {
  display: none;
}

.react-select__indicators {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 16px;
}

.react-select__control {
  padding: 28px 16px 12px;
  border-radius: 12px;
  border: none !important;
}
.react-select__control.react-select__control--is-focused {
  outline: 2px solid var(--hero-borders-anchor) !important;
  background: var(--default-surfaces-base);
}

.react-select__indicator path {
  fill: rgba(23, 23, 23, 0.5);
}
.react-select__menu {
  z-index: 2 !important;
  min-width: 100%;
  margin-top: 4px !important;
  background-color: var(--default-surfaces-base) !important;
  border-radius: 12px !important;
  box-shadow: 0px 20px 60px 0px rgba(0, 0, 0, 0.1) !important;
  padding: 8px;
  overflow-y: auto;
  border: 0;
}
.react-select__menu-list {
  padding: 0 !important;
}
.react-select__menu-list > div {
  color: var(--default-symbols-primary);
  /*--color-secondary: var(--default-symbols-secondary);*/
  display: block;
  padding: 12px 8px;
  white-space: nowrap;
  min-height: unset;
  border-radius: 6px !important;
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: -.01em;
  cursor: pointer;
}
.react-select__menu-list > div:hover,
.react-select__option.react-select__option--is-focused {
  background: var(--default-surfaces-light);
}
.react-select__menu-list > div.react-select__option--is-selected {
  background-color: var(--default-surfaces-anchor);
  color: var(--inverted-symbols-primary)
}

/*div#react-select-2-listbox {*/
/*  position: absolute;*/
/*  width: auto;*/
/*  min-width: 100%;*/
/*  top: 100%;*/
/*  margin-top: 2px;*/
/*  background-color: var(--default-surfaces-base);*/
/*  border-radius: 12px;*/
/*  box-shadow: 0px 20px 60px 0px rgba(0,0,0,0.1);*/
/*  padding: 8px;*/
/*  overflow-y: auto;*/
/*  border: 0;*/
/*}*/

/*div#react-select-2-option-1 {*/
/*  color: var(--color, var(--default-symbols-primary));*/
/*  --color-secondary: var(--default-symbols-secondary);*/
/*  display: block;*/
/*  padding: 8px;*/
/*  border-radius: 6px;*/
/*  white-space: nowrap;*/
/*  line-height: unset;*/
/*  min-height: unset;*/
/*  border-radius: 6px !important;*/
/*  font-size: 13px;*/
/*  font-weight: 700;*/
/*  line-height: 16px;*/
/*  letter-spacing: -.01em;*/
/*}*/

/*div#react-select-2-option-0 {-*/
/*color: var(--inverted-symbols-primary);*/
/*  --color-secondary: var(--inverted-symbols-secondary);*/
/*  background-color: var(--default-surfaces-anchor);*/
/*  border-radius: 6px;*/
/*}*/

/*.react-select__menu-list.css-1n6sfyn-MenuList {*/
/*  padding: 0;*/
/*}*/

/*div#react-select-2-option-1:hover {*/
/*  background: var(--default-surfaces-light);*/
/*}*/