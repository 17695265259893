.menu-item {
  background-color: transparent;
  border: none;
  border-radius: 6px;
  /* color: #6870f6; */
  color: #b5b5c3;
  height: 24px;
  margin-right: 4px;
  padding: 0;
  /* width: 1.75rem; */
  cursor: pointer;
  position: relative;
}

.left-item svg {
  color: var(--default-surfaces-ghost);
  fill: var(--default-surfaces-ghost)
}

.menu-item[disabled] {
  pointer-events: none;
  opacity: 0.5;
}

/* .menu-item svg {
  fill: currentColor;
  height: 100%;
  width: 100%;
} */

.menu-item:hover{
  background: var(--default-surfaces-ghost-hv)
}
/* .menu-item:hover, */
.is-active {
  /* background-color: #6870f6; */
  background-color: var(--default-surfaces-light-hv);
  color: #fff;
}
