.writerAccessModal__header{
    display: inline-flex;
    align-items: center;
    cursor: pointer;
}

.writerAccessModal__header p{
    color: var(--default-symbols-secondary);
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    margin-left: 4px
}

.writerAccessModal__header path{
    fill: var(--default-symbols-secondary);
}

.writerAccessModal__body{
    display: flex;
    gap: 4px;
    margin-top: 8px
}

.writerAccessModal__body svg{
    /* width: 120px; */
    /* height: 200px */

    flex-grow: 1
}

.writerAccessModal__body_first{
    max-width: 650px
}

.writerAccessModal_title{
    font-weight: 800;
    font-size: 62px;
    line-height:68px;
    color: var(--default-symbols-primary);
    margin-bottom: 16px
}

.writerAccesssModal_description{
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    color: var(--default-symbols-primary);
    margin-bottom: 4px
}

.modal-body-footer{
    margin-top: 36px;
    width: 400px
}

.modal-body-footer .footer-button{
    width: 400px;
    border-radius: 20px;
    outline: none;
    border: none;
    height: 45px;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: var(--inverted-symbols-primary);
    cursor:pointer
}

.modal-body-footer .footer-button.night{
    background: var(--night-surfaces-anchor);;
}
.modal-body-footer .footer-button.default{
    background: var(--default-surfaces-anchor);
}

.modal-body-footer .checkbox-container {
    display: flex;
    align-items: center;
    gap: 4px;
    justify-content: center;
    margin-top:4px
} 

.modal-body-footer .checkbox-container p{
    font-size: 13px;
    font-weight: 500;
    line-height: 16px;
    color: var(--default-symbols-primary)
}


/* The container */
.container-checkbox {
    display: block;
    position: relative;
    padding-left: 20px;
    margin-bottom: 20px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.container-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

/* Create a custom checkbox */
.container-checkbox .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border-radius: 4px;
    background-color: rgba(23, 23, 23, 0.05);
    border-radius: 4px
}


/* On mouse-over, add a grey background color */
.container-checkbox:hover input ~ .checkmark {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container-checkbox input:checked ~ .checkmark {
    background-color: var(--default-symbols-primary);
}

/* Create the checkmark/indicator (hidden when not checked) */
.container-checkbox .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.container-checkbox input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.container-checkbox .checkmark:after {
    left: 6px;
    top: 2px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
