.articles-sidebar__wrapper.absolute-sidebar {
    display: flex;
    align-items: center;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 3;
}

.articles-sidebar {
    width: 100%;
    max-width: 470px;
    min-width: 470px;
    height: 100%;
    background: var(--default-surfaces-base);
    z-index: 1;
    position: relative;
    display: flex;
    flex-direction: column;
}

.articles-sidebar-content {
    overflow: auto;
}

.articles-sidebar-content .no-results{
    text-align: center;
}