.option-button {
  font: inherit;
  padding: 0.5rem 1.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  background: white;
  color: gray;
  cursor: pointer;
  margin-right: 1rem;
  text-decoration: none;
  display: inline-block;
  font-weight: bold;
}

.option-button:focus {
  outline: none;
}

.option-button--true {
  border: 1px solid #6E78F7;
  border-radius: 4px;
  background: #6E78F7;
  color: white;
  cursor: pointer;
  margin-right: 1rem;
  text-decoration: none;
  display: inline-block;
  font-weight: bold;
}
