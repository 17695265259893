.hiwb {
    display: flex;
    align-items: flex-start;
}

.hiwb_step {
    font-size: 18px;
    font-weight: 800;
    background: var(--night-symbols-primary);
    border-radius: 50px;
    width: 30px;
    min-width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--default-surfaces-base);
    margin-right: 8px;
    margin-top: 6px;
}

.hiwb_title {
    font-size: 13px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: -0.01em;
    color: var(--default-symbols-primary);
}

.hiwb_text {
    color: var(--default-symbols-primary);
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.01em;
}

.hiwb_text span {
    font-weight: 700;
    cursor: pointer;
}
