.divider {
  background-color: #d9e1e9;
  height: 1.25rem;
  margin-left: 0.5rem;
  margin-right: 0.75rem;
  width: 2px;
}

.editor__header {
  display: flex;
  padding: 14px 24px;
  align-items: center;
  justify-content: space-between;
  padding: 6px 32px 12px 32px;
  border-bottom: 1px solid var(--default-borders-light, rgba(23, 23, 23, 0.05));
}

.editor__header .editor__header-left{
  display: flex;
  align-items: center
}

.editor__header .editor__header-right{
  display: flex;
  align-items: center
}

.upload-progress-wrapper {
  background: white;
  padding: 30px;
  border-radius: 20px;
  min-width: 360px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
}

.upload-progress-message {
  font-size: 14px;
  color: #384359;
  font-weight: 700;
  margin-bottom: 10px;
}

.upload-progress-error {
  font-size: 14px;
  color: #ec5032;
  font-weight: 700;
  margin-bottom: 10px;
}

.upload-progress-bar {
  width: 200px;
  height: 14px;
  background-color: #b5b5c3;
  border-radius: 9px;
}
.edit__header-left-container{
  display: flex;
  align-items: center;
  gap: 16px
}
