.app-overlay.open {
    opacity: 1;
}

.app-overlay {
    background: var(--default-surfaces-dark);
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 1;
    opacity: 0;
    transition: 0.5s ease;
}