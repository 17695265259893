.progress-metric{
    padding: 4px 0;
}
.progress-metric.disabled .progress-value {
    background: var(--default-symbols-secondary) !important;
}
.progress-metric.disabled p,
.progress-metric.disabled path {
    color: var(--default-symbols-secondary) !important;
    fill: var(--default-symbols-secondary)
}

.progress-metric:not(:first-child){
    margin-top: 4px
}


.progress-metric .progress-heading{
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    cursor: default;
}
.progress-heading.loading {
    font-size: 0;
}

.progress-heading .plagiarism-row{
    display: flex;
    align-items: center
}

.plagiarism-row svg{
    cursor: pointer;
    margin-right: 2px
}

.plagiarism-row .rotating {
    -webkit-animation: rotating 2s linear infinite;
    -moz-animation: rotating 2s linear infinite;
    -ms-animation: rotating 2s linear infinite;
    -o-animation: rotating 2s linear infinite;
    animation: rotating 2s linear infinite;
}
.progress-metric .progress-heading p{
    color: var(--symbols-primary);
    font-size: 11px;
    font-weight: 500;
}

.progress-metric .progress-heading .disabled{
    color: var(--default-symbols-secondary)
}

.progress-metric .progress-container{
    width: 100%;
    border-radius: 99px;
    height: 2px;
    background: rgba(23, 23, 23, 0.05);
    position: relative;
}

.progress-metric .progress-container .progress-value{
    height: 100%;
    border-radius: 99px;
    background: var(--default-symbols-primary);
    margin-top: 2px
}
.progress-heading:hover .tooltip-container {
    display: block;
}
.tooltip-container {
    font-family: "Inter", sans-serif;
    display: none;
    position: absolute;
    right: 100%;
    top: 50%;
    transform: translateY(-50%);
    background: #fff;
    border-radius: 12px;
    padding: 8px 12px;
    font-size: 13px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: -0.01em;
    color: var(--default-symbols-primary);
    width: 220px;
}


 @-webkit-keyframes rotating /* Safari and Chrome */ {
                from {
                    -webkit-transform: rotate(0deg);
                    -o-transform: rotate(0deg);
                    transform: rotate(0deg);
                }
                to {
                    -webkit-transform: rotate(360deg);
                    -o-transform: rotate(360deg);
                    transform: rotate(360deg);
                    }
                }
                @keyframes rotating {
                from {
                    -ms-transform: rotate(0deg);
                    -moz-transform: rotate(0deg);
                    -webkit-transform: rotate(0deg);
                    -o-transform: rotate(0deg);
                    transform: rotate(0deg);
                }
                to {
                    -ms-transform: rotate(360deg);
                    -moz-transform: rotate(360deg);
                    -webkit-transform: rotate(360deg);
                    -o-transform: rotate(360deg);
                    transform: rotate(360deg);
                    }
                }