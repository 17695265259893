
.switcher {
    margin: 0;
    display: flex;
    white-space: nowrap;
    gap: 8px;
    cursor: pointer;
    align-items: center;
}
.switcher input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkmark {
    height: 24px;
    width: 40px;
    background-color: var(--night-symbols-secondary);
    position: relative;
    display: inline-block;
    border-radius: 24px;
    transition: 0.1s linear;
    user-select: none;
}

.switcher-label{
    color: var(--night-symbols-primary);
    font-size: 13px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: -0.01em;
}

.switcher:hover input ~ .checkmark {
    background-color: var(--night-symbols-secondary);
}
.switcher input:checked ~ .checkmark {
    background-color: var(--night-symbols-primary);
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.switcher input:checked ~ .checkmark:after {
    left: 20px;
}
.switcher .checkmark:after {
    left: 4px;
    top: 4px;
    width: 16px;
    height: 16px;
    background: white;
    border-radius: 50%;
    display: block;
    transition: 0.1s linear;
}