.ai-card-style {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 0;
    padding: 12px 0 0;
}

.no-style__title {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 16px;
}

.no-style__icon {
    margin-bottom: 8px;
}

.no-style__icon svg {
    width: 54px;
}

.no-style__icon path {
    fill: white;
}

.no-style__button {
    display: flex;
    font-size: 13px;
    font-weight: 700;
    padding: 8px 16px;
    line-height: 16px;
    align-items: center;
    border-radius: 50px;
    letter-spacing: -0.01em;
    color: var(--default-symbols-primary);
    background: var(--inverted-surfaces-anchor);
    cursor: pointer;
}

.no-style__button svg {
    margin-right: 4px;
}

.no-style__button path {
    fill: var(--default-symbols-primary);
}

.ai-card-style__options {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px 16px;
}
.ai-card-style__actions {
    display: flex;
    gap: 8px;
}

.ai-card-style__button {
    background: var(--inverted-surfaces-light);
    border-radius: 50px;
    padding: 8px 16px;
    font-size: 13px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: -0.01em;
    color: var(--inverted-symbols-primary);
    display: flex;
    align-items: center;
    gap: 4px;
    cursor: pointer;
}

.ai-card-style__button path {
    fill: var(--inverted-symbols-primary);
}

.ai-card-style .app-tab-selector {
    width: 100%;
}