:root {
  --color-dark-text: #384359;
  --color-light-text: #75819b;
  --color-active-input: #6870f6;
  --color-inactive-input: #d9e1e9;
  --color-error-input: #ec5032;
  --color-success-input: #66ca95;

  --fancy-font: "GT Super Display";
  --fancy-font-size: 26px;
  --fancy-font-line-height: 40px;

  --default-font: "Inter, sans-serif";
}

:root .facelift {
    --default-symbols-primary: rgba(23, 23, 23, 1);
    --default-symbols-secondary: rgba(23, 23, 23, 0.5);
    --default-symbols-secondary-hv: rgba(23, 23, 23, 0.6);
    --default-surfaces-light: rgba(23, 23, 23, 0.05);
    --default-surfaces-light-hv: rgba(23, 23, 23, 0.07);
    --default-surfaces-anchor: rgba(23, 23, 23, 1);
    --default-surfaces-anchor-hv: rgba(23, 23, 23, 0.9);
    --default-surfaces-dark: rgba(23, 23, 23, 0.3);
    --default-surfaces-dark-hv: rgba(23, 23, 23, 0.4);
    --default-surfaces-medium: rgba(23, 23, 23, 0.15);
    --default-surfaces-medium-hv: rgba(23, 23, 23, 0.2);
    --default-borders-light: rgba(23, 23, 23, 0.05);
    --default-borders-light-hv: rgba(23, 23, 23, 0.07);
    --default-borders-anchor: rgba(23, 23, 23, 1);
    --default-borders-anchor-hv: rgba(23, 23, 23, 0.9);
    --default-surfaces-base: rgba(255, 255, 255, 1);
    --default-symbols-primary-hv: rgba(23, 23, 23, 0.9);
    --default-surfaces-ghost: rgba(23, 23, 23, 0);
    --default-surfaces-ghost-hv: rgba(23, 23, 23, 0.05);
    --inverted-symbols-primary: rgba(255, 255, 255, 1);
    --inverted-symbols-primary-hv: rgba(255, 255, 255, 0.9);
    --inverted-symbols-secondary: rgba(255, 255, 255, 0.5);
    --inverted-symbols-secondary-hv: rgba(255, 255, 255, 0.6);
    --inverted-surfaces-base: rgba(0, 0, 0, 1);
    --inverted-surfaces-anchor: rgba(255, 255, 255, 1);
    --inverted-surfaces-anchor-hv: rgba(255, 255, 255, 0.9);
    --inverted-surfaces-light: rgba(255, 255, 255, 0.05);
    --inverted-surfaces-light-hv: rgba(255, 255, 255, 0.07);
    --inverted-surfaces-medium: rgba(255, 255, 255, 0.15);
    --inverted-surfaces-medium-hv: rgba(255, 255, 255, 0.2);
    --inverted-surfaces-dark: rgba(255, 255, 255, 0.3);
    --inverted-surfaces-dark-hv: rgba(255, 255, 255, 0.4);
    --inverted-borders-light: rgba(255, 255, 255, 0.05);
    --inverted-surfaces-ghost: rgba(255, 255, 255, 0);
    --inverted-surfaces-ghost-hv: rgba(255, 255, 255, 0.05);
    --inverted-borders-light-hv: rgba(255, 255, 255, 0.07);
    --inverted-borders-anchor: rgba(255, 255, 255, 1);
    --inverted-borders-anchor-hv: rgba(255, 255, 255, 0.9);
    --positive-symbols-primary: rgba(65, 150, 44, 1);
    --positive-symbols-primary-hv: rgba(65, 150, 44, 0.9);
    --positive-symbols-secondary: rgba(65, 150, 44, 0.5);
    --positive-symbols-secondary-hv: rgba(65, 150, 44, 0.6);
    --positive-surfaces-anchor: rgba(65, 150, 44, 1);
    --positive-surfaces-anchor-hv: rgba(65, 150, 44, 0.9);
    --positive-surfaces-light: rgba(65, 150, 44, 0.08);
    --positive-surfaces-light-hv: rgba(65, 150, 44, 0.1);
    --positive-surfaces-medium: rgba(65, 150, 44, 0.15);
    --positive-surfaces-medium-hv: rgba(65, 150, 44, 0.2);
    --positive-surfaces-dark: rgba(65, 150, 44, 0.3);
    --positive-surfaces-dark-hv: rgba(65, 150, 44, 0.4);
    --positive-borders-light: rgba(65, 150, 44, 0.05);
    --positive-surfaces-ghost: rgba(65, 150, 44, 0);
    --positive-surfaces-ghost-hv: rgba(65, 150, 44, 0.05);
    --positive-borders-light-hv: rgba(65, 150, 44, 0.07);
    --positive-borders-anchor: rgba(65, 150, 44, 1);
    --positive-borders-anchor-hv: rgba(65, 150, 44, 0.9);
    --negative-symbols-primary: rgba(220, 67, 33, 1);
    --negative-symbols-primary-hv: rgba(220, 67, 33, 0.9);
    --negative-symbols-secondary: rgba(220, 67, 33, 0.5);
    --negative-symbols-secondary-hv: rgba(220, 67, 33, 0.6);
    --negative-surfaces-anchor: rgba(220, 67, 33, 1);
    --negative-surfaces-anchor-hv: rgba(220, 67, 33, 0.9);
    --negative-surfaces-light: rgba(220, 67, 33, 0.07);
    --negative-surfaces-light-hv: rgba(220, 67, 33, 0.1);
    --negative-surfaces-medium: rgba(220, 67, 33, 0.15);
    --negative-surfaces-medium-hv: rgba(220, 67, 33, 0.2);
    --negative-surfaces-dark: rgba(220, 67, 33, 0.3);
    --negative-surfaces-dark-hv: rgba(220, 67, 33, 0.4);
    --negative-borders-light: rgba(220, 67, 33, 0.05);
    --negative-surfaces-ghost: rgba(220, 67, 33, 0);
    --negative-surfaces-ghost-hv: rgba(220, 67, 33, 0.05);
    --negative-borders-light-hv: rgba(220, 67, 33, 0.07);
    --negative-borders-anchor: rgba(220, 67, 33, 1);
    --negative-borders-anchor-hv: rgba(220, 67, 33, 0.9);
    --normal-symbols-primary: rgba(224, 144, 26, 1);
    --normal-symbols-primary-hv: rgba(224, 144, 26, 0.9);
    --normal-symbols-secondary: rgba(224, 144, 26, 0.5);
    --normal-symbols-secondary-hv: rgba(224, 144, 26, 0.6);
    --normal-surfaces-anchor: rgba(224, 144, 26, 1);
    --normal-surfaces-anchor-hv: rgba(224, 144, 26, 0.9);
    --normal-surfaces-light: rgba(224, 144, 26, 0.08);
    --normal-surfaces-light-hv: rgba(224, 144, 26, 0.1);
    --normal-surfaces-medium: rgba(224, 144, 26, 0.15);
    --normal-surfaces-medium-hv: rgba(224, 144, 26, 0.2);
    --normal-surfaces-dark: rgba(224, 144, 26, 0.3);
    --normal-surfaces-dark-hv: rgba(224, 144, 26, 0.4);
    --normal-borders-light: rgba(224, 144, 26, 0.05);
    --normal-surfaces-ghost: rgba(224, 144, 26, 0);
    --normal-surfaces-ghost-hv: rgba(224, 144, 26, 0.05);
    --normal-borders-light-hv: rgba(224, 144, 26, 0.07);
    --normal-borders-anchor: rgba(224, 144, 26, 1);
    --normal-borders-anchor-hv: rgba(224, 144, 26, 0.9);
    --minor-symbols-primary: rgba(102, 102, 102, 1);
    --minor-symbols-primary-hv: rgba(102, 102, 102, 0.9);
    --minor-symbols-secondary: rgba(102, 102, 102, 0.5);
    --minor-symbols-secondary-hv: rgba(102, 102, 102, 0.6);
    --minor-surfaces-anchor: rgba(102, 102, 102, 1);
    --minor-surfaces-anchor-hv: rgba(102, 102, 102, 0.9);
    --minor-surfaces-light: rgba(102, 102, 102, 0.08);
    --minor-surfaces-light-hv: rgba(102, 102, 102, 0.1);
    --minor-surfaces-medium: rgba(102, 102, 102, 0.15);
    --minor-surfaces-medium-hv: rgba(102, 102, 102, 0.2);
    --minor-surfaces-dark: rgba(102, 102, 102, 0.3);
    --minor-surfaces-dark-hv: rgba(102, 102, 102, 0.4);
    --minor-borders-light: rgba(102, 102, 102, 0.05);
    --minor-surfaces-ghost: rgba(102, 102, 102, 0);
    --minor-surfaces-ghost-hv: rgba(102, 102, 102, 0.05);
    --minor-borders-light-hv: rgba(102, 102, 102, 0.07);
    --minor-borders-anchor: rgba(102, 102, 102, 1);
    --minor-borders-anchor-hv: rgba(102, 102, 102, 0.9);
    --danger-symbols-primary: rgba(235, 79, 79, 1);
    --danger-symbols-primary-hv: rgba(235, 79, 79, 0.9);
    --danger-symbols-secondary: rgba(235, 79, 79, 0.5);
    --danger-symbols-secondary-hv: rgba(235, 79, 79, 0.6);
    --danger-surfaces-anchor: rgba(235, 79, 79, 1);
    --danger-surfaces-anchor-hv: rgba(235, 79, 79, 0.9);
    --danger-surfaces-light: rgba(235, 79, 79, 0.08);
    --danger-surfaces-light-hv: rgba(235, 79, 79, 0.1);
    --danger-surfaces-medium: rgba(235, 79, 79, 0.15);
    --danger-surfaces-medium-hv: rgba(235, 79, 79, 0.2);
    --danger-surfaces-dark: rgba(235, 79, 79, 0.3);
    --danger-surfaces-dark-hv: rgba(235, 79, 79, 0.4);
    --danger-borders-light: rgba(235, 79, 79, 0.05);
    --danger-surfaces-ghost: rgba(235, 79, 79, 0);
    --danger-surfaces-ghost-hv: rgba(235, 79, 79, 0.05);
    --danger-borders-light-hv: rgba(235, 79, 79, 0.07);
    --danger-borders-anchor: rgba(235, 79, 79, 1);
    --danger-borders-anchor-hv: rgba(235, 79, 79, 0.9);
    --alert-symbols-primary: rgba(233, 190, 37, 1);
    --alert-symbols-primary-hv: rgba(233, 190, 37, 0.9);
    --alert-symbols-secondary: rgba(233, 190, 37, 0.5);
    --alert-symbols-secondary-hv: rgba(233, 190, 37, 0.6);
    --alert-surfaces-anchor: rgba(233, 190, 37, 1);
    --alert-surfaces-anchor-hv: rgba(233, 190, 37, 0.9);
    --alert-surfaces-light: rgba(233, 190, 37, 0.08);
    --alert-surfaces-light-hv: rgba(233, 190, 37, 0.1);
    --alert-surfaces-medium: rgba(233, 190, 37, 0.15);
    --alert-surfaces-medium-hv: rgba(233, 190, 37, 0.2);
    --alert-surfaces-dark: rgba(233, 190, 37, 0.3);
    --alert-surfaces-dark-hv: rgba(233, 190, 37, 0.4);
    --alert-borders-light: rgba(233, 190, 37, 0.05);
    --alert-surfaces-ghost: rgba(233, 190, 37, 0);
    --alert-surfaces-ghost-hv: rgba(233, 190, 37, 0.05);
    --alert-borders-light-hv: rgba(233, 190, 37, 0.07);
    --alert-borders-anchor: rgba(233, 190, 37, 1);
    --alert-borders-anchor-hv: rgba(233, 190, 37, 0.9);
    --new-symbols-primary: rgba(68, 126, 207, 1);
    --new-symbols-primary-hv: rgba(68, 126, 207, 0.9);
    --new-symbols-secondary: rgba(68, 126, 207, 0.5);
    --new-symbols-secondary-hv: rgba(68, 126, 207, 0.6);
    --new-surfaces-anchor: rgba(68, 126, 207, 1);
    --new-surfaces-anchor-hv: rgba(68, 126, 207, 0.9);
    --new-surfaces-light: rgba(68, 126, 207, 0.08);
    --new-surfaces-light-hv: rgba(68, 126, 207, 0.1);
    --new-surfaces-medium: rgba(68, 126, 207, 0.15);
    --new-surfaces-medium-hv: rgba(68, 126, 207, 0.2);
    --new-surfaces-dark: rgba(68, 126, 207, 0.3);
    --new-surfaces-dark-hv: rgba(68, 126, 207, 0.4);
    --new-borders-light: rgba(68, 126, 207, 0.05);
    --new-surfaces-ghost: rgba(68, 126, 207, 0);
    --new-surfaces-ghost-hv: rgba(68, 126, 207, 0.05);
    --new-borders-light-hv: rgba(68, 126, 207, 0.07);
    --new-borders-anchor: rgba(68, 126, 207, 1);
    --new-borders-anchor-hv: rgba(68, 126, 207, 0.9);
    --success-symbols-primary: rgba(82, 171, 60, 1);
    --success-symbols-primary-hv: rgba(82, 171, 60, 0.9);
    --success-symbols-secondary: rgba(82, 171, 60, 0.5);
    --success-symbols-secondary-hv: rgba(82, 171, 60, 0.6);
    --success-surfaces-anchor: rgba(82, 171, 60, 1);
    --success-surfaces-anchor-hv: rgba(82, 171, 60, 0.9);
    --success-surfaces-light: rgba(82, 171, 60, 0.08);
    --success-surfaces-light-hv: rgba(82, 171, 60, 0.1);
    --success-surfaces-medium: rgba(82, 171, 60, 0.15);
    --success-surfaces-medium-hv: rgba(82, 171, 60, 0.2);
    --success-surfaces-dark: rgba(82, 171, 60, 0.3);
    --success-surfaces-dark-hv: rgba(82, 171, 60, 0.4);
    --success-borders-light: rgba(82, 171, 60, 0.05);
    --success-surfaces-ghost: rgba(82, 171, 60, 0);
    --success-surfaces-ghost-hv: rgba(82, 171, 60, 0.05);
    --success-borders-light-hv: rgba(82, 171, 60, 0.07);
    --success-borders-anchor: rgba(82, 171, 60, 1);
    --success-borders-anchor-hv: rgba(82, 171, 60, 0.9);
    --green-symbols-primary: rgba(82, 171, 60, 1);
    --green-symbols-primary-hv: rgba(82, 171, 60, 0.9);
    --green-symbols-secondary: rgba(82, 171, 60, 0.5);
    --green-symbols-secondary-hv: rgba(82, 171, 60, 0.6);
    --green-surfaces-anchor: rgba(82, 171, 60, 1);
    --green-surfaces-anchor-hv: rgba(82, 171, 60, 0.9);
    --green-surfaces-light: rgba(82, 171, 60, 0.08);
    --green-surfaces-light-hv: rgba(82, 171, 60, 0.1);
    --green-surfaces-medium: rgba(82, 171, 60, 0.15);
    --green-surfaces-medium-hv: rgba(82, 171, 60, 0.2);
    --green-surfaces-dark: rgba(82, 171, 60, 0.3);
    --green-surfaces-dark-hv: rgba(82, 171, 60, 0.4);
    --green-borders-light: rgba(82, 171, 60, 0.05);
    --green-surfaces-ghost: rgba(82, 171, 60, 0);
    --green-surfaces-ghost-hv: rgba(82, 171, 60, 0.05);
    --green-borders-light-hv: rgba(82, 171, 60, 0.07);
    --green-borders-anchor: rgba(82, 171, 60, 1);
    --green-borders-anchor-hv: rgba(82, 171, 60, 0.9);
    --acid-symbols-primary: rgba(129, 217, 60, 1);
    --acid-symbols-primary-hv: rgba(129, 217, 60, 0.9);
    --acid-symbols-secondary: rgba(129, 217, 60, 0.5);
    --acid-symbols-secondary-hv: rgba(129, 217, 60, 0.6);
    --acid-surfaces-anchor: rgba(129, 217, 60, 1);
    --acid-surfaces-anchor-hv: rgba(129, 217, 60, 0.9);
    --acid-surfaces-light: rgba(129, 217, 60, 0.08);
    --acid-surfaces-light-hv: rgba(129, 217, 60, 0.1);
    --acid-surfaces-medium: rgba(129, 217, 60, 0.15);
    --acid-surfaces-medium-hv: rgba(129, 217, 60, 0.2);
    --acid-surfaces-dark: rgba(129, 217, 60, 0.3);
    --acid-surfaces-dark-hv: rgba(129, 217, 60, 0.4);
    --acid-borders-light: rgba(129, 217, 60, 0.05);
    --acid-surfaces-ghost: rgba(129, 217, 60, 0);
    --acid-surfaces-ghost-hv: rgba(129, 217, 60, 0.05);
    --acid-borders-light-hv: rgba(129, 217, 60, 0.07);
    --acid-borders-anchor: rgba(129, 217, 60, 1);
    --acid-borders-anchor-hv: rgba(129, 217, 60, 0.9);
    --blue-symbols-primary: rgba(114, 174, 219, 1);
    --blue-symbols-primary-hv: rgba(114, 174, 219, 0.9);
    --blue-symbols-secondary: rgba(114, 174, 219, 0.5);
    --blue-symbols-secondary-hv: rgba(114, 174, 219, 0.6);
    --blue-surfaces-anchor: rgba(114, 174, 219, 1);
    --blue-surfaces-anchor-hv: rgba(114, 174, 219, 0.9);
    --blue-surfaces-light: rgba(114, 174, 219, 0.08);
    --blue-surfaces-light-hv: rgba(114, 174, 219, 0.1);
    --blue-surfaces-medium: rgba(114, 174, 219, 0.15);
    --blue-surfaces-medium-hv: rgba(114, 174, 219, 0.2);
    --blue-surfaces-dark: rgba(114, 174, 219, 0.3);
    --blue-surfaces-dark-hv: rgba(114, 174, 219, 0.4);
    --blue-borders-light: rgba(114, 174, 219, 0.05);
    --blue-surfaces-ghost: rgba(114, 174, 219, 0);
    --blue-surfaces-ghost-hv: rgba(114, 174, 219, 0.05);
    --blue-borders-light-hv: rgba(114, 174, 219, 0.07);
    --blue-borders-anchor: rgba(114, 174, 219, 1);
    --blue-borders-anchor-hv: rgba(114, 174, 219, 0.9);
    --brown-symbols-primary: rgba(183, 123, 69, 1);
    --brown-symbols-primary-hv: rgba(183, 123, 69, 0.9);
    --brown-symbols-secondary: rgba(183, 123, 69, 0.5);
    --brown-symbols-secondary-hv: rgba(183, 123, 69, 0.6);
    --brown-surfaces-anchor: rgba(183, 123, 69, 1);
    --brown-surfaces-anchor-hv: rgba(183, 123, 69, 0.9);
    --brown-surfaces-light: rgba(183, 123, 69, 0.08);
    --brown-surfaces-light-hv: rgba(183, 123, 69, 0.1);
    --brown-surfaces-medium: rgba(183, 123, 69, 0.15);
    --brown-surfaces-medium-hv: rgba(183, 123, 69, 0.2);
    --brown-surfaces-dark: rgba(183, 123, 69, 0.3);
    --brown-surfaces-dark-hv: rgba(183, 123, 69, 0.4);
    --brown-borders-light: rgba(183, 123, 69, 0.05);
    --brown-surfaces-ghost: rgba(183, 123, 69, 0);
    --brown-surfaces-ghost-hv: rgba(183, 123, 69, 0.05);
    --brown-borders-light-hv: rgba(183, 123, 69, 0.07);
    --brown-borders-anchor: rgba(183, 123, 69, 1);
    --brown-borders-anchor-hv: rgba(183, 123, 69, 0.9);
    --yellow-symbols-primary: rgba(233, 190, 37, 1);
    --yellow-symbols-primary-hv: rgba(233, 190, 37, 0.9);
    --yellow-symbols-secondary: rgba(233, 190, 37, 0.5);
    --yellow-symbols-secondary-hv: rgba(233, 190, 37, 0.6);
    --yellow-surfaces-anchor: rgba(233, 190, 37, 1);
    --yellow-surfaces-anchor-hv: rgba(233, 190, 37, 0.9);
    --yellow-surfaces-light: rgba(233, 190, 37, 0.08);
    --yellow-surfaces-light-hv: rgba(233, 190, 37, 0.1);
    --yellow-surfaces-medium: rgba(233, 190, 37, 0.15);
    --yellow-surfaces-medium-hv: rgba(233, 190, 37, 0.2);
    --yellow-surfaces-dark: rgba(233, 190, 37, 0.3);
    --yellow-surfaces-dark-hv: rgba(233, 190, 37, 0.4);
    --yellow-borders-light: rgba(233, 190, 37, 0.05);
    --yellow-surfaces-ghost: rgba(233, 190, 37, 0);
    --yellow-surfaces-ghost-hv: rgba(233, 190, 37, 0.05);
    --yellow-borders-light-hv: rgba(233, 190, 37, 0.07);
    --yellow-borders-anchor: rgba(233, 190, 37, 1);
    --yellow-borders-anchor-hv: rgba(233, 190, 37, 0.9);
    --orange-symbols-primary: rgba(238, 146, 61, 1);
    --orange-symbols-primary-hv: rgba(238, 146, 61, 0.9);
    --orange-symbols-secondary: rgba(238, 146, 61, 0.5);
    --orange-symbols-secondary-hv: rgba(238, 146, 61, 0.6);
    --orange-surfaces-anchor: rgba(238, 146, 61, 1);
    --orange-surfaces-anchor-hv: rgba(238, 146, 61, 0.9);
    --orange-surfaces-light: rgba(238, 146, 61, 0.08);
    --orange-surfaces-light-hv: rgba(238, 146, 61, 0.1);
    --orange-surfaces-medium: rgba(238, 146, 61, 0.15);
    --orange-surfaces-medium-hv: rgba(238, 146, 61, 0.2);
    --orange-surfaces-dark: rgba(238, 146, 61, 0.3);
    --orange-surfaces-dark-hv: rgba(238, 146, 61, 0.4);
    --orange-borders-light: rgba(238, 146, 61, 0.05);
    --orange-surfaces-ghost: rgba(238, 146, 61, 0);
    --orange-surfaces-ghost-hv: rgba(238, 146, 61, 0.05);
    --orange-borders-light-hv: rgba(238, 146, 61, 0.07);
    --orange-borders-anchor: rgba(238, 146, 61, 1);
    --orange-borders-anchor-hv: rgba(238, 146, 61, 0.9);
    --red-symbols-primary: rgba(238, 82, 82, 1);
    --red-symbols-primary-hv: rgba(238, 82, 82, 0.9);
    --red-symbols-secondary: rgba(238, 82, 82, 0.5);
    --red-symbols-secondary-hv: rgba(238, 82, 82, 0.6);
    --red-surfaces-anchor: rgba(238, 82, 82, 1);
    --red-surfaces-anchor-hv: rgba(238, 82, 82, 0.9);
    --red-surfaces-light: rgba(238, 82, 82, 0.08);
    --red-surfaces-light-hv: rgba(238, 82, 82, 0.1);
    --red-surfaces-medium: rgba(238, 82, 82, 0.15);
    --red-surfaces-medium-hv: rgba(238, 82, 82, 0.2);
    --red-surfaces-dark: rgba(238, 82, 82, 0.3);
    --red-surfaces-dark-hv: rgba(238, 82, 82, 0.4);
    --red-borders-light: rgba(238, 82, 82, 0.05);
    --red-surfaces-ghost: rgba(238, 82, 82, 0);
    --red-surfaces-ghost-hv: rgba(238, 82, 82, 0.05);
    --red-borders-light-hv: rgba(238, 82, 82, 0.07);
    --red-borders-anchor: rgba(238, 82, 82, 1);
    --red-borders-anchor-hv: rgba(238, 82, 82, 0.9);
    --pink-symbols-primary: rgba(218, 120, 234, 1);
    --pink-symbols-primary-hv: rgba(218, 120, 234, 0.9);
    --pink-symbols-secondary: rgba(218, 120, 234, 0.5);
    --pink-symbols-secondary-hv: rgba(218, 120, 234, 0.6);
    --pink-surfaces-anchor: rgba(218, 120, 234, 1);
    --pink-surfaces-anchor-hv: rgba(218, 120, 234, 0.9);
    --pink-surfaces-light: rgba(218, 120, 234, 0.08);
    --pink-surfaces-light-hv: rgba(218, 120, 234, 0.1);
    --pink-surfaces-medium: rgba(218, 120, 234, 0.15);
    --pink-surfaces-medium-hv: rgba(218, 120, 234, 0.2);
    --pink-surfaces-dark: rgba(218, 120, 234, 0.3);
    --pink-surfaces-dark-hv: rgba(218, 120, 234, 0.4);
    --pink-borders-light: rgba(218, 120, 234, 0.05);
    --pink-surfaces-ghost: rgba(218, 120, 234, 0);
    --pink-surfaces-ghost-hv: rgba(218, 120, 234, 0.05);
    --pink-borders-light-hv: rgba(218, 120, 234, 0.07);
    --pink-borders-anchor: rgba(218, 120, 234, 1);
    --pink-borders-anchor-hv: rgba(218, 120, 234, 0.9);
    --purple-symbols-primary: rgba(190, 86, 207, 1);
    --purple-symbols-primary-hv: rgba(190, 86, 207, 0.9);
    --purple-symbols-secondary: rgba(190, 86, 207, 0.5);
    --purple-symbols-secondary-hv: rgba(190, 86, 207, 0.6);
    --purple-surfaces-anchor: rgba(190, 86, 207, 1);
    --purple-surfaces-anchor-hv: rgba(190, 86, 207, 0.9);
    --purple-surfaces-light: rgba(190, 86, 207, 0.08);
    --purple-surfaces-light-hv: rgba(190, 86, 207, 0.07);
    --purple-surfaces-medium: rgba(190, 86, 207, 0.15);
    --purple-surfaces-medium-hv: rgba(190, 86, 207, 0.2);
    --purple-surfaces-dark: rgba(190, 86, 207, 0.3);
    --purple-surfaces-dark-hv: rgba(190, 86, 207, 0.4);
    --purple-borders-light: rgba(190, 86, 207, 0.05);
    --purple-surfaces-ghost: rgba(190, 86, 207, 0);
    --purple-surfaces-ghost-hv: rgba(190, 86, 207, 0.05);
    --purple-borders-light-hv: rgba(190, 86, 207, 0.07);
    --purple-borders-anchor: rgba(190, 86, 207, 1);
    --purple-borders-anchor-hv: rgba(190, 86, 207, 0.9);
    --night-symbols-primary: rgba(116, 94, 202, 1);
    --night-symbols-alternative: rgba(135, 117, 210, 1);
    --night-symbols-primary-hv: rgba(116, 94, 202, 0.9);
    --night-symbols-secondary: rgba(116, 94, 202, 0.5);
    --night-symbols-secondary-hv: rgba(116, 94, 202, 0.6);
    --night-surfaces-anchor: rgba(116, 94, 202, 1);
    --night-surfaces-anchor-hv: rgba(116, 94, 202, 0.9);
    --night-surfaces-light: rgba(116, 94, 202, 0.08);
    --night-surfaces-light-hv: rgba(116, 94, 202, 0.07);
    --night-surfaces-medium: rgba(116, 94, 202, 0.15);
    --night-surfaces-medium-hv: rgba(116, 94, 202, 0.2);
    --night-surfaces-dark: rgba(116, 94, 202, 0.3);
    --night-surfaces-dark-hv: rgba(116, 94, 202, 0.4);
    --night-borders-light: rgba(116, 94, 202, 0.05);
    --night-surfaces-ghost: rgba(116, 94, 202, 0);
    --night-surfaces-ghost-hv: rgba(116, 94, 202, 0.05);
    --night-borders-light-hv: rgba(116, 94, 202, 0.07);
    --night-borders-anchor: rgba(116, 94, 202, 1);
    --night-borders-anchor-hv: rgba(116, 94, 202, 0.9);
    --black-symbols-primary: rgba(23, 23, 23, 1);
    --black-symbols-primary-hv: rgba(23, 23, 23, 0.9);
    --black-symbols-secondary: rgba(23, 23, 23, 0.5);
    --black-symbols-secondary-hv: rgba(23, 23, 23, 0.6);
    --black-surfaces-anchor: rgba(23, 23, 23, 1);
    --black-surfaces-anchor-hv: rgba(23, 23, 23, 0.9);
    --black-surfaces-light: rgba(23, 23, 23, 0.08);
    --black-surfaces-light-hv: rgba(23, 23, 23, 0.07);
    --black-surfaces-medium: rgba(23, 23, 23, 0.15);
    --black-surfaces-medium-hv: rgba(23, 23, 23, 0.2);
    --black-surfaces-dark: rgba(23, 23, 23, 0.3);
    --black-surfaces-dark-hv: rgba(23, 23, 23, 0.4);
    --black-borders-light: rgba(23, 23, 23, 0.05);
    --black-surfaces-ghost: rgba(23, 23, 23, 0);
    --black-surfaces-ghost-hv: rgba(23, 23, 23, 0.05);
    --black-borders-light-hv: rgba(23, 23, 23, 0.07);
    --black-borders-anchor: rgba(23, 23, 23, 1);
    --black-borders-anchor-hv: rgba(23, 23, 23, 0.9);
    --gray-symbols-primary: rgba(137, 145, 156, 1);
    --gray-symbols-primary-hv: rgba(137, 145, 156, 0.9);
    --gray-symbols-secondary: rgba(137, 145, 156, 0.5);
    --gray-symbols-secondary-hv: rgba(137, 145, 156, 0.6);
    --gray-surfaces-anchor: rgba(137, 145, 156, 1);
    --gray-surfaces-anchor-hv: rgba(137, 145, 156, 0.9);
    --gray-surfaces-light: rgba(137, 145, 156, 0.08);
    --gray-surfaces-light-hv: rgba(137, 145, 156, 0.07);
    --gray-surfaces-medium: rgba(137, 145, 156, 0.15);
    --gray-surfaces-medium-hv: rgba(137, 145, 156, 0.2);
    --gray-surfaces-dark: rgba(137, 145, 156, 0.3);
    --gray-surfaces-dark-hv: rgba(137, 145, 156, 0.4);
    --gray-borders-light: rgba(137, 145, 156, 0.05);
    --gray-surfaces-ghost: rgba(137, 145, 156, 0);
    --gray-surfaces-ghost-hv: rgba(137, 145, 156, 0.05);
    --gray-borders-light-hv: rgba(137, 145, 156, 0.07);
    --gray-borders-anchor: rgba(137, 145, 156, 1);
    --gray-borders-anchor-hv: rgba(137, 145, 156, 0.9);
    --hero-symbols-primary: rgba(64, 226, 70, 1);
    --hero-symbols-primary-hv: rgba(64, 226, 70, 0.9);
    --hero-symbols-secondary: rgba(64, 226, 70, 0.5);
    --hero-symbols-secondary-hv: rgba(64, 226, 70, 0.6);
    --hero-surfaces-anchor: rgba(64, 226, 70, 1);
    --hero-surfaces-anchor-hv: rgba(64, 226, 70, 0.9);
    --hero-surfaces-light: rgba(64, 226, 70, 0.08);
    --hero-surfaces-light-hv: rgba(64, 226, 70, 0.1);
    --hero-surfaces-medium: rgba(64, 226, 70, 0.15);
    --hero-surfaces-medium-hv: rgba(64, 226, 70, 0.2);
    --hero-surfaces-dark: rgba(64, 226, 70, 0.3);
    --hero-surfaces-dark-hv: rgba(64, 226, 70, 0.4);
    --hero-borders-light: rgba(64, 226, 70, 0.05);
    --hero-surfaces-ghost: rgba(64, 226, 70, 0);
    --hero-surfaces-ghost-hv: rgba(64, 226, 70, 0.05);
    --hero-borders-light-hv: rgba(64, 226, 70, 0.07);
    --hero-borders-anchor: rgba(64, 226, 70, 1);
    --hero-borders-anchor-hv: rgba(64, 226, 70, 0.9);
}
