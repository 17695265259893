.h1-article-heading {
  font-size: 14px;
  font-weight: 500;
  color: #384359;
  line-height: 23px;
  margin-right: 30px;
}

.article-heading {
  font-size: 13px;
  line-height: 20px;
  letter-spacing: -0.01em;
  font-weight: 500;
}

.article-heading .heading-level {
  font-weight: 700;
  text-transform: uppercase;
  margin-right: 4px;
}

.h3-article-heading {
  margin-left: 24px;
}

.h4-article-heading {
  margin-left: 48px;
}
