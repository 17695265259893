.content-audit-box {
    color: var(--default-symbols-primary);
    border-radius: 12px;
    cursor: pointer;
    padding: 8px;
    display: grid;
    gap: 4px;
}

.content-audit-box:not(.disabled):hover {
    background: var(--default-surfaces-ghost-hv);
}
.content-audit-box.questions-active{
    border: 3px solid rgba(64, 226, 70, 1)
}

.content-audit-box.disabled {
    cursor: not-allowed;
}
.content-audit-box.disabled > * {
    opacity: 0.5;
}

.content-audit-box_title {
    font-size: 13px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: -0.01em;
}
.content-audit-box-count{
    font-size: 11px;
    font-weight: 700;
    line-height: 14px;
}
