.serp-container {
    border: 1px solid rgba(23, 23, 23, 0.05);
    border-radius: 8px;
    margin-top: 24px;
    margin-bottom: 24px;
    position: relative;
    overflow: hidden;
    background: var(--new-surfaces-light)
}

.serp-container .serp-heading{
    font-size: 15px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: -0.01em;
    text-align: left;
    color: var(--new-symbols-primary, rgba(68, 126, 207, 1));
}
.serp-container .serp-heading__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    cursor: pointer;
}
.serp-body {
    margin-top: -8px;
    padding: 0 16px 16px;
}
.serp-container .serp-heading__wrapper path {
    fill: var(--new-symbols-primary, rgba(68, 126, 207, 1));
}

.serp-container .serp-heading-description{
    font-size: 15px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: -0.01em;
    text-align: left;
    color: var(--default-symbols-primary, rgba(23, 23, 23, 1));
}

.serp-container .serp-trigger-button{
    font-weight: 700;
    font-size: 13px;
    line-height: 16px;
    color:var(--default-symbols-primary, rgba(23, 23, 23, 1));
    border-radius: 20px;
    letter-spacing: -0.01em;
    background: var(--inverted-surfaces-anchor, rgba(255, 255, 255, 1));
    padding: 8px 16px;
    outline: none;
    border: none;
    margin-top: 16px;
    cursor: pointer;
    display: flex;
    align-items: center
}

.serp-trigger-button.check-progress{
   color: rgba(23, 23, 23, 0.5);
   cursor: not-allowed;
}


.serp-trigger-button svg path{
    color: rgba(23, 23, 23, 0.5);
    fill: rgba(23, 23, 23, 0.5)
}
.serp-trigger-button svg {
    margin-left: 4px;
    animation: rotation 3s infinite;
}

.serp-container .serp-footer-text{
    color: var(--default-symbols-secondary, rgba(23, 23, 23, 0.5));
    font-weight: 500;
    font-size: 11px;
    line-height: 14px;
    margin-top: 4px;
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}


.background-icon{
    position: absolute;
    right: 0;
    bottom: 16px;
    z-index: 2;
}
.background-icon path {
    fill: var(--new-surfaces-light, rgba(68, 126, 207, 0.08));
}