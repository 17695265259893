@import '../_global-vars.css';

.custom-checkbox input {
  /*display:flex;*/
  /*padding: 20px 0 0;*/
  display: none;
  opacity: 0;
  z-index: -999;
  visibility: hidden;
  position: absolute;
  left: 0;
  top: 0;
  height: 0;
  bottom: 0;
}

.checkbox-label {

  display: flex;
  align-items: center;
  cursor: pointer;
}

.checkbox {
  background-color: var(--default-surfaces-light);
  color: var(--default-symbols-primary);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  padding: 0 2px;
  transition: background-color 0.2s ease-out;
  margin-right: 8px;

}
.checkbox svg {
  opacity: 0;
  width: 16px;
  height: 16px;
}

/*.custom-checkbox input:checked + .checkbox-icon,*/
.custom-checkbox input:checked + .checkbox svg {
  opacity: 1;
  fill: var(--inverted-symbols-primary);
}
.custom-checkbox input:checked + .checkbox {
  background-color: var(--inverted-surfaces-base);
  color: var(--inverted-symbols-primary);
  /*outline: 2px solid var(--hero-borders-anchor);*/
}

.checkbox-label-text {
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.01em;
}

.info-tooltip {
  margin-left: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.info-tooltip svg * {
  fill: currentColor;
}
.custom-checkbox.reverse .checkbox {
  background-color: var(--inverted-surfaces-anchor);
}

.custom-checkbox.reverse input:checked + .checkbox svg {
  opacity: 1;
  fill: var(--default-symbols-primary);
}
.custom-checkbox.reverse input:checked + .checkbox {
  background-color: var(--inverted-surfaces-anchor);
  color: var(--inverted-symbols-primary);
}

.custom-checkbox.reverse .checkbox-label-text {
  color: var(--inverted-symbols-primary);
}