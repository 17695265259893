.modal-content-audit {
    margin-top: 24px;
}
.new-article-modal__content.new-article-modal__content--new-user .modal-content-audit {
    max-width: 100%;
}
.content-audit__title {
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: -0.02em;
    margin-bottom: 8px;
}
.content-audit__boxes {
    background: var(--default-surfaces-light);
    padding: 16px;
    border-radius: 12px;
}
.content-audit__opportunities {
    padding: 8px;
    display: grid;
    gap: 2px;
}
.content-audit__opportunities-count {
    font-size: 32px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: -0.02em;
}
.content-audit__opportunities-label {
    font-size: 15px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: -0.01em;
}
