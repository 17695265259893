.default-settings-sidebar__content label {
    display: block;
    font-size: 15px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: -0.01em;
    margin-bottom: 8px;
}
.default-settings-sidebar__content label span {
    font-size: 15px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: -0.01em;
    color: var(--default-symbols-secondary);
}

.default-settings-sidebar__content label span.has-error {
    color: var(--danger-symbols-primary);
}

.default-settings-sidebar__content textarea {
    width: 100%;
    margin-bottom: 8px;
    background: var(--default-surfaces-light);
    padding: 12px 16px 36px;
    border-radius: 12px;
    font-size: 15px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: -0.01em;
    border: 2px solid transparent;
    flex-grow: 1;
}

.default-settings-sidebar__content textarea::placeholder {
    color: var(--default-symbols-secondary);
}

.default-settings-sidebar__content textarea:focus-visible {
    outline: none;
    border-color: var(--hero-borders-anchor);
    background: var(--default-surfaces-base)
}

.default-settings-info {
    font-size: 13px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: -0.01em;
    color: var(--default-symbols-secondary);
}

.default-settings-textarea__wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    position: relative;
}

.default-settings-textarea__wrapper .prompt__counter {
    position: absolute;
    bottom: 12px;
    right: 16px;
    font-size: 13px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: -0.01em;
    color: var(--default-symbols-primary);
}

.default-settings-textarea__wrapper .prompt__counter.exceed {
    color: var(--danger-symbols-primary)
}