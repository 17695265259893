.react-datepicker__triangle {
    display: none !important;
}
.article-date-picker {
    border-radius: 12px;
    width: 100%;
    border: none;
    position: relative;
}

.article-date-picker__label {
    font-size: 11px;
    font-weight: 800;
    line-height: 14px;
    position: absolute;
    left: 18px;
    top: 12px;
    z-index: 1;
}

.article-date-picker__input-wrapper {
    position: relative;
    width: 100%;
}

.article-date-picker__input {
    background: var(--default-surfaces-light);
    border: 2px solid transparent;
    padding: 26px 16px 12px;
    width: 100%;
    border-radius: 8px;
    cursor: pointer;
    font-size: 15px;
    font-weight: 700;
    line-height: 18px;
}

.article-date-picker__input:hover {
    /* border-color: #CCCCCC; */
}

.article-date-picker__input:focus {
    background: var(--default-surfaces-base);
    border-color: var(--hero-borders-anchor);
    color: var(--default-symbols-primary);
    outline: none;
}

/* Override react-datepicker styles */
.react-datepicker-wrapper {
    width: 100%;
}

.react-datepicker {
    font-family: inherit;
    border-radius: 8px;
    border: 1px solid var(--default-borders-light);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.react-datepicker__header {
    background-color: var(--default-surfaces-light);
    border-bottom: 1px solid var(--default-borders-light);
    border-radius: 8px 8px 0 0;
    padding-top: 12px;
}

.react-datepicker__current-month {
    font-weight: 500;
    font-size: 14px;
}

.react-datepicker__day-name {
    color: var(--default-symbols-primary);
}

.react-datepicker__day {
    border-radius: 4px;
}


.react-datepicker__day--selected {
    background-color: var(--night-symbols-primary);
    color: var(--inverted-symbols-primary);
}

.react-datepicker__day--keyboard-selected {
    background-color: #F0F7FF;
    color: var(--night-symbols-primary);
}